import React from 'react';
import { AlertStatus } from './alerts';
import Header from '../components/utils/header/header';

export interface HeaderPropsValues {
  totalFilteredTrains: number;
  totalRed: number;
  totalAmber: number;
  userName: string;
  showNumbers: boolean;
  title: string;
  showOrderBy: boolean;
  setFilter: any;
  typeVehicle: string;
}

export interface HeaderPropsActions {
  totalFilter: (type: AlertStatus) => void;
}

export type HeaderProps = HeaderPropsValues & HeaderPropsActions;

const StatusHeader = ({
  totalFilteredTrains,
  totalRed,
  showNumbers,
  title,
  totalAmber,
  setFilter,
  typeVehicle,
  userName,
  showOrderBy,
  totalFilter
}: HeaderProps) => {
  return (
    <Header
      typeVehicle={typeVehicle}
      setFilter={setFilter}
      subtitle={typeVehicle}
      firstNumber={totalFilteredTrains}
      secondNumber={totalRed}
      thirdNumber={totalAmber}
      firstText={typeVehicle}
      secondText={typeVehicle}
      thirdText={typeVehicle}
      title={title}
      showNumbers={showNumbers}
      totalFilter={totalFilter}
      showOrderBy={showOrderBy}
    />
  );
};

StatusHeader.defaultProps = {
  totalFilteredTrains: 0,
  totalRed: 0,
  totalAmber: 0,
  userName: '',
  showOrderBy: false
};

export { StatusHeader };
