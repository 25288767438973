import React, { Fragment, PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DatePickerRange from '../../reports/reportsTable/datePickerRange';
import TreeSelect from './treeSelect';
import BubbleTreeReport from './bubbleTreeReports';
import SwitchButton from '../../reports/switch-button';
import PdfReport from '../../reports/PdfReport';
import { Col, Row } from 'react-bootstrap';

const ComponentContainer = styled.div`
  display: ${props => (props.showFiltersOnly ? 'flex' : 'none')};
  margin: 0 40px;
  :first-child {
    margin-left: 0;
  }
  position: relative;
  z-index: 10;
`;

const FilterContainer = styled.div`
  width: 22%;
  margin-top: 30px;
  /*transform: translateX(35px);*/
`;

const DownloadReportContainer = styled.div`
  margin-left: 20px;
  margin-top: 30px;
`;

const SwithFilterContainer = styled.div`
  width: 82%;
  margin-left: 20px;
  margin-top: 30px;
`;

const HierarchyContainer = styled.div`
  width: auto;
  margin-top: 1px;
  margin-right: 20px;
  margin-top: 30px;

  /* .infinite-scroll-component {
    display: flex;
    flex-direction: column;
  } */
  .tag-list {
    display: inline-block;
    flex-flow: row-reverse;
    flex-wrap: wrap-reverse;
  }
  .tag-item .tag {
    margin: 5px 0;
    display: inline-flex;
    align-items: center;
  }
  .node.disabled > * {
    cursor: default;
  }
`;

const DatePickerContainer = styled(FilterContainer)``;
const SwitchContainer = styled(SwithFilterContainer)``;
const DownloadReportButtonContainer = styled(DownloadReportContainer)``;

const filterReferencesBasedOnFleets = (value, state) => {
  let result = false;
  if (!state.fleetsSelected.length) {
    result = true;
  } else if (
    state.fleetsSelected.some(
      fleetSelected => value.fleetType === fleetSelected.value
    )
  ) {
    result = true;
  }
  return result;
};

const filterReferencesBasedOnVehicles = (value, state) => {
  let result = false;
  if (!state.vehiclesSelected.length) {
    result = true;
  } else if (
    state.vehiclesSelected.some(
      vehicleSelected => value.designation === vehicleSelected.value
    )
  ) {
    result = true;
  }
  return result;
};

const filterByHierarchy = arr => {
  const arrReversed = arr.reverse();
  const result = arrReversed.find(element => element.value !== 'All');
  return ((result !== -1 || result !== undefined) && result) || null;
};

class FiltersContainerReports extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCompletedEvents: false,
      fleetValues: [],
      vehiclesValues: [],
      carriagesValues: [],
      fleetsSelected: [],
      vehiclesSelected: [],
      carriagesSelected: [],
      hierarchySelected: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.fleetValues !== state.fleetValues) {
      const fleetValues = props.sfk_VehiclesReferences
        .filter(() =>
          state.fleetsSelected.length >= 1
            ? state.fleetsSelected[0].value !== 'All'
            : true
        )
        .map(value => {
          return value.fleetType;
        });

      const vehiclesValues = props.sfk_VehiclesReferences
        .filter(() =>
          state.vehiclesSelected.length >= 1
            ? state.vehiclesSelected[0].value !== 'All'
            : true
        )
        .filter(value => {
          const result =
            state.fleetsSelected.length &&
            state.fleetsSelected[0].value !== 'All'
              ? filterReferencesBasedOnFleets(value, state)
              : true;
          return result;
        })
        .map(value => value.designation);

      const carriagesValues = props.mergeArrays(
        props.sfk_VehiclesReferences
          .filter(() =>
            state.carriagesSelected.length >= 1
              ? state.carriagesSelected[0].value !== 'All'
              : true
          )
          .filter(value =>
            state.fleetsSelected.length &&
            state.fleetsSelected[0].value !== 'All'
              ? filterReferencesBasedOnFleets(value, state)
              : true
          )
          .filter(value =>
            state.vehiclesSelected.length &&
            state.vehiclesSelected[0].value !== 'All'
              ? filterReferencesBasedOnVehicles(value, state)
              : true
          )
          .map(value => value.carriageNumber)
      );
      let bubbleHierarchy = {};
      if (
        state.vehiclesSelected.length &&
        state.carriagesSelected.length &&
        state.fleetsSelected.length
      ) {
        const hierarchyArray = filterByHierarchy([
          state.fleetsSelected,
          state.vehiclesSelected,
          state.carriagesSelected
        ]);
        bubbleHierarchy = {
          fleetsSelected: [],
          vehiclesSelected: [],
          carriagesSelected: [],
          hierarchySelected: [...state.hierarchySelected, hierarchyArray],
          fleetValues: [
            'All',
            ...props.sfk_VehiclesReferences.map(value => {
              return value.fleetType;
            })
          ],
          vehiclesValues: [
            'All',
            ...props.sfk_VehiclesReferences.map(value => value.designation)
          ],
          carriagesValues: props.mergeArrays([
            'All',
            ...props.sfk_VehiclesReferences.map(value => value.carriageNumber)
          ])
        };
      }

      const myNewState = {
        fleetValues: !state.fleetsSelected.length
          ? ['All', ...fleetValues]
          : [...fleetValues],
        vehiclesValues: !state.vehiclesSelected.length
          ? ['All', ...vehiclesValues]
          : [...vehiclesValues],
        carriagesValues: !state.carriagesSelected.length
          ? ['All', ...carriagesValues]
          : [...carriagesValues],
        ...bubbleHierarchy
      };

      return myNewState;
    }

    return null;
  }

  handleHierarchyOnChange = event => {
    let value = event.value;

    let auxhierarchySelected = this.state.hierarchySelected;
    if (!this.state.hierarchySelected.includes(value)) {
      auxhierarchySelected.push(value);
      this.setState({ hierarchySelected: auxhierarchySelected });
    } else {
      auxhierarchySelected = auxhierarchySelected.filter(e => e !== value);
      this.setState({ hierarchySelected: auxhierarchySelected });
    }
    sessionStorage.setItem('filterVehicles', auxhierarchySelected.toString());
    this.props.reportsMainCall(auxhierarchySelected);
  };

  handleBubbleClick = ({ value, root }) => {
    if (value === 'Act' || value === 'ActNow' || value === 'Plan')
      this.props.handleAssetStatusChange(value);
    if (value === 'Temperature' || value === 'Vibration' || value === 'Sensor')
      this.props.handleMeasuramentTypeChange(value);

    let auxhierarchySelected = this.state.hierarchySelected;
    auxhierarchySelected = auxhierarchySelected.filter(e => e !== value);
    sessionStorage.setItem('filterVehicles', auxhierarchySelected.toString());
    this.setState({ hierarchySelected: auxhierarchySelected });
    this.props.reportsMainCall(auxhierarchySelected);
  };

  handleMesTypeOnChange = value => {
    this.props.handleMeasuramentTypeChange(value.value);
  };

  handleAssetStatusChange = value => {
    this.props.handleAssetStatusChange(value.value);
  };

  filterChilds = (valueSelected, valueToChange) => {
    let referencetoGet = '';
    switch (valueToChange) {
      case 'vehiclesValues':
        referencetoGet = 'designation';
        break;
      case 'carriagesValues':
        referencetoGet = 'referenceCarriage';
        break;
      default:
        break;
    }

    const result = [];
    const selected = this.state[valueSelected];
    selected.forEach(value => {
      const vehiclesfiltered = this.props.sfk_VehiclesReferences.filter(
        element => {
          return element.elementType === value;
        }
      );
      result.push(vehiclesfiltered[referencetoGet]);
    });
    this.setState({ [valueToChange]: result });
  };

  handleFleetOnChange = newValue => {
    this.setState({ fleetsSelected: newValue });
  };

  handleVehicleOnChange = newValue => {
    this.setState({ vehiclesSelected: newValue });
  };

  handleCarriageOnChange = newValue => {
    this.setState({ carriagesSelected: newValue });
  };

  handleOnClick = (newValue, calledFrom) => {
    switch (calledFrom) {
      case 'fleet':
        this.handleFleetOnChange(newValue);
        break;
      case 'vehicle':
        this.handleVehicleOnChange(newValue);
        break;
      case 'carriage':
        this.handleCarriageOnChange(newValue);
        break;
      default:
        break;
    }
  };

  constructOptions = values => {
    return (
      values
        // .filter(arr => this.filterValues(arr))
        .map(value => {
          return { value, label: value };
        })
    );
  };

  handleHideCompletedEventsStatusChange = value => {
    this.setState({ showCompletedEvents: value });
    if (this.props.handleHideCompletedEventsStatusChange)
      this.props.handleHideCompletedEventsStatusChange(value);
  };

  componentDidMount() {
    const sfilterVehicles = sessionStorage.getItem('filterVehicles');
    let filterVehicles = '';
    if (sfilterVehicles) filterVehicles = sfilterVehicles.split(',');

    if (
      filterVehicles &&
      filterVehicles !== '' &&
      sfilterVehicles &&
      sfilterVehicles !== ''
    ) {
      this.setState({ hierarchySelected: filterVehicles });
      this.props.reportsMainCall(filterVehicles);
    }
  }

  render() {
    const {
      handleStartDateChange,
      handleEndDateChange,
      startDate,
      endDate,
      minDate,
      maxDate,
      selectedDate,
      measuramentTypeSelected,
      assetStatusSelected,
      skf_TreeData,
      hierarchySelected,
      isMeasTypeFilter,
      isVehicleFilter,
      isDateFilter,
      isAssetFilter,
      isDownloadReport,
      showFiltersOnly,
      eventsFleet,
      dataPdf
    } = this.props;

    return (
      <Fragment>
        <ComponentContainer
          className="Reports_Filter"
          showFiltersOnly={showFiltersOnly}
        >
          <Row>
            {isVehicleFilter && (
              <Col>
                <HierarchyContainer isDateFilter={isDateFilter}>
                  <TreeSelect
                    placeholder="Search or Select"
                    title={''}
                    skf_TreeData={skf_TreeData}
                    onChange={value => this.handleHierarchyOnChange(value)}
                    value={hierarchySelected}
                    singleSelect
                    valuesSelected={hierarchySelected}
                  />
                </HierarchyContainer>
              </Col>
            )}
            {isAssetFilter && (
              <Col>
                <HierarchyContainer isDateFilter={isDateFilter}>
                  <TreeSelect
                    placeholder="Action"
                    title=""
                    skf_TreeData={[
                      {
                        key: 'ActNow',
                        pId: 0,
                        title: 'Act Now',
                        value: 'ActNow'
                      },
                      { key: 'Act', pId: 0, title: 'Act', value: 'Act' },
                      { key: 'Plan', pId: 0, title: 'Plan', value: 'Plan' }
                    ]}
                    onChange={value => this.handleAssetStatusChange(value)}
                    value={assetStatusSelected}
                    singleSelect
                    valuesSelected={assetStatusSelected}
                  />
                </HierarchyContainer>
              </Col>
            )}
            {isMeasTypeFilter && (
              <Col>
                <HierarchyContainer isDateFilter={isDateFilter}>
                  <TreeSelect
                    placeholder="Report Type"
                    title=""
                    skf_TreeData={[
                      {
                        key: 'Temperature',
                        pId: 0,
                        title: 'Temperature',
                        value: 'Temperature'
                      },
                      {
                        key: 'Vibration',
                        pId: 0,
                        title: 'Vibration',
                        value: 'Vibration'
                      },
                      {
                        key: 'Sensor',
                        pId: 0,
                        title: 'Sensor',
                        value: 'Sensor'
                      }
                    ]}
                    value={measuramentTypeSelected}
                    onChange={value => this.handleMesTypeOnChange(value)}
                    singleSelect
                    valuesSelected={measuramentTypeSelected}
                  />
                </HierarchyContainer>
              </Col>
            )}
            {isDateFilter && (
              <Col>
                <DatePickerContainer>
                  <DatePickerRange
                    handleStartDateChange={handleStartDateChange}
                    handleEndDateChange={handleEndDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    selectedDate={selectedDate}
                  />
                </DatePickerContainer>
              </Col>
            )}
            <Col>
              <SwitchContainer>
                <br></br>
                <SwitchButton
                  value={this.props.onlyOpen}
                  label="Hide Closed Events"
                  onChange={this.props.setOnlyOpen}
                />
              </SwitchContainer>
            </Col>
            {isDownloadReport && (
              <Col>
                <DownloadReportButtonContainer>
                  <PdfReport
                    startDate={startDate}
                    endDate={endDate}
                    onlyOpen={this.props.onlyOpen}
                    measuramentValue={measuramentTypeSelected}
                    actionValue={assetStatusSelected}
                    hierarchyValue={hierarchySelected.map(
                      value => skf_TreeData?.find(e => e.key === value)?.title
                    )}
                    eventsFleet={eventsFleet}
                    dataPdf={dataPdf}
                  />
                </DownloadReportButtonContainer>
              </Col>
            )}
          </Row>
        </ComponentContainer>
        {((hierarchySelected && hierarchySelected.length > 0) ||
          (assetStatusSelected && assetStatusSelected.length > 0) ||
          (measuramentTypeSelected && measuramentTypeSelected.length > 0)) && (
          <BubbleTreeReport
            treeData={skf_TreeData}
            hierarchySelected={hierarchySelected}
            assetStatusSelected={assetStatusSelected}
            measuramentTypeSelected={measuramentTypeSelected}
            handleBubbleClick={this.handleBubbleClick}
          />
        )}
      </Fragment>
    );
  }
}

FiltersContainerReports.defaultProps = {
  endDate: Date.now(),
  fleetValues: [],
  handleAssetStatusChange: () => null,
  handleEndDateChange: () => null,
  handleMeasuramentTypeChange: () => null,
  handleStartDateChange: () => null,
  handleHideCompletedEventsStatusChange: () => false,
  isAssetFilter: true,
  isDateFilter: true,
  isMeasTypeFilter: true,
  isVehicleFilter: true,
  isCloseEventsFilter: true,
  isDownloadReport: true,
  maxDate: Date.now(),
  selectedDate: Date.now(),
  minDate: Date.now(),
  reportsMainCall: () => null,
  sfk_VehiclesReferences: [],
  skf_ReferencesForFilter: [],
  startDate: Date.now(),
  showFiltersOnly: true,
  hierarchyTitle: 'Fleet, Search or Select'
};

FiltersContainerReports.propTypes = {
  assetStatusSelected: PropTypes.any,
  endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  fleetValues: PropTypes.array,
  handleAssetStatusChange: PropTypes.func,
  handleBubbleClick: PropTypes.any,
  handleEndDateChange: PropTypes.func,
  handleMeasuramentTypeChange: PropTypes.func,
  handleHideCompletedEventsStatusChange: PropTypes.func,
  handleStartDateChange: PropTypes.func,
  hierarchySelected: PropTypes.any,
  isAssetFilter: PropTypes.bool,
  isDateFilter: PropTypes.bool,
  isMeasTypeFilter: PropTypes.bool,
  isVehicleFilter: PropTypes.bool,
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  measuramentTypeSelected: PropTypes.any,
  mergeArrays: PropTypes.any,
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  reportsMainCall: PropTypes.func,
  sfk_VehiclesReferences: PropTypes.array,
  skf_ReferencesForFilter: PropTypes.array,
  skf_TreeData: PropTypes.any,
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  showFiltersOnly: PropTypes.bool,
  hierarchyTitle: PropTypes.string
};

export default FiltersContainerReports;
