import React, { Component } from 'react';
import SkfModal from '../reports/SkfModal';
import styled from 'styled-components';
import { Table, Form, Col, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faCaretRight,
  faCaretLeft,
  faDownload,
  faFolderOpen
} from '@fortawesome/free-solid-svg-icons';
import ModalNewDocument from './modalNewDocument';
import ModalCategoy from './modalCategory';
import { fleetService } from '../../services/fleet.service';

function GetSortOrder(prop) {
  return function(a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

interface IEventLog {
  userName: string;
  eventCaseId: string;
  comment: string;
  dateTime: Date;
  status: string;
  color: string;
}

export interface IEventLogProps {
  eventReference: string;
  isOpen: boolean;
  closeModal: any;
  assetStatus: number;
  createdOn: string;
  createdBy: string;
  roles: string[];
  treeData: any;
  documents: any;
  setID: string;
  vehicleNumber: string;
  bogiePosition: string;
}

export interface StateProps {
  events: IEventLog[];
  isLoading: boolean;
  page: number;
  numPerPage: number;
  pages: number;
  filter: string;
  category: string;
  documents: any;
  categorys: any;
  newDocument: boolean;
  categoryOpen: boolean;
}

class ModalDocuments extends Component<IEventLogProps, StateProps> {
  constructor(props: any) {
    super(props);
  }

  public readonly state: Readonly<StateProps> = {
    events: [],
    isLoading: true,
    page: 0,
    numPerPage: 10,
    pages: 0,
    documents: [],
    categorys: [],
    filter: '',
    category: '',
    newDocument: false,
    categoryOpen: false
  };

  beforePage = () => {
    let page = this.state.page;
    page = page - 1;
    if (page < 0) {
      page = 0;
    }
    this.setState({
      page
    });
  };

  nextPage = () => {
    let lastPage = this.state.pages - 1;
    let page = this.state.page;
    page = page + 1;
    if (page > lastPage) {
      page = lastPage + 1;
    }
    this.setState({
      page
    });
  };

  setFilter = (e: string) => {
    this.setState({ filter: e });
  };

  setnewDocument = () => {
    this.setState({ newDocument: !this.state.newDocument });
  };

  setCategory = (e: string) => {
    this.setState({ category: e });
  };

  setCategoryOpen = () => {
    this.setState({ categoryOpen: !this.state.categoryOpen });
  };

  componentDidMount() {
    fleetService.getDocumentcategory().then((response: any) => {
      this.setState({ categorys: response.sort(GetSortOrder('position')) });
    });
  }

  componentWillReceiveProps(newprops: IEventLogProps) {
    const client = sessionStorage.getItem('client');
    const level = sessionStorage.getItem('typeVehicle');

    if (
      this.props.setID !== newprops.setID ||
      this.props.vehicleNumber !== newprops.vehicleNumber ||
      this.props.bogiePosition !== newprops.bogiePosition
    )
      if (level === 'Bogies' && newprops.bogiePosition === undefined) {
      } else {
        fleetService
          .getDocuments(
            client,
            level,
            newprops.setID,
            newprops.vehicleNumber,
            newprops.bogiePosition
          )
          .then((response: any) => {
            if (!response.errMessage) this.setState({ documents: response });
          });
      }
  }

  setCategorys = (categorys: any) => {
    this.setState({ categorys: categorys.sort(GetSortOrder('position')) });
  };

  setDocuments = (documents: any) => {
    this.setState({ documents: documents });
  };

  downloadFile = (url: string, fileName: string) => {
    fleetService.getDocumentFile(url, fileName).then(async (response: any) => {
      var blob = new Blob([response.data]);
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);

      link.download = fileName;
      link.click();
    });
  };

  render() {
    return (
      <div>
        {this.props.roles.includes(
          'CoMo-Visualization-User-Admin-Document_Read'
        ) && (
          <IconContainer
            icon={faFolderOpen}
            style={{
              position: 'absolute',
              top: 20,
              right: 160,
              cursor: 'pointer'
            }}
            onClick={this.props.closeModal}
          />
        )}
        <SkfModal
          size="large"
          isOpen={this.props.isOpen}
          handleClose={this.props.closeModal}
        >
          <SkfModal.Header handleClose={this.props.closeModal}>
            Online Repository
          </SkfModal.Header>
          <SkfModal.Content>
            <ModalNewDocument
              treeData={this.props.treeData}
              roles={this.props.roles}
              setCategorys={this.setCategorys}
              categorys={this.state.categorys}
              closeModal={this.setnewDocument}
              isOpen={this.state.newDocument}
              documents={this.state.documents}
              setID={this.props.setID}
              vehicleNumber={this.props.vehicleNumber}
              bogiePosition={this.props.bogiePosition}
              setDocuments={this.setDocuments}
            ></ModalNewDocument>
            <ModalCategoy
              roles={this.props.roles}
              setCategorys={this.setCategorys}
              categorys={this.state.categorys}
              closeModal={this.setCategoryOpen}
              isOpen={this.state.categoryOpen}
            ></ModalCategoy>

            <Row>
              <Col sm="12" md="12" lg="5">
                <Form.Group
                  sm="6"
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column>Filter</Form.Label>
                  <Col sm="10">
                    <Form.Control
                      onChange={e => this.setFilter(e.target.value)}
                      type="text"
                      placeholder="Filter"
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm="12" md="12" lg="6">
                <Form.Group
                  sm="6"
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="2">
                    Category
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      onChange={e => this.setCategory(e.target.value)}
                      as="select"
                      name="selectedToBucket"
                    >
                      <option value={''}>{''}</option>
                      {this.state.categorys &&
                        this.state.categorys
                          .filter(c => c.status === 'ENABLED')
                          .map((e: any, i: number) => (
                            <option key={i} value={e.category}>
                              {e.category}
                            </option>
                          ))}
                    </Form.Control>
                  </Col>
                  <Col sm="3">
                    {this.props.roles.includes(
                      'CoMo-Visualization-User-Admin-Document-Categories_Read'
                    ) &&
                      this.props.roles.includes(
                        'CoMo-Visualization-User-Admin-Document-Categories_Create'
                      ) && (
                        <Button
                          variant="primary"
                          onClick={this.setCategoryOpen}
                        >
                          New Category
                        </Button>
                      )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <div style={{ width: '90%', marginBottom: 15 }}>
              <Table className="border font-weight-bold" size="sm">
                <thead>
                  <tr className="text-center">
                    <th className="align-middle">Title</th>
                    <th className="align-middle">Desciption</th>
                    <th className="align-middle">Category</th>
                    <th className="align-middle">Document</th>
                    <th className="align-middle"></th>
                  </tr>
                </thead>
                <tbody className="battleship">
                  {this.state.documents &&
                    this.state.documents?.map((e: any, i: number) => {
                      if (
                        this.state.filter === '' ||
                        e.title.includes(this.state.filter) ||
                        e.description.includes(this.state.filter)
                      )
                        if (
                          this.state.category === '' ||
                          e.category.includes(this.state.category)
                        )
                          return (
                            <tr className={'text-center'} key={i}>
                              <td>{e.title} </td>
                              <td>{e.description} </td>
                              <td>{e.category} </td>
                              <td> {e.fileName}</td>
                              <td>
                                {' '}
                                <FontAwesomeIcon
                                  style={{ cursor: 'pointer' }}
                                  icon={faDownload}
                                  onClick={() =>
                                    this.downloadFile(e.uploadFile, e.fileName)
                                  }
                                />
                              </td>
                            </tr>
                          );
                    })}
                </tbody>
              </Table>
            </div>
            <BtnPaginator onClick={() => this.beforePage()}>
              <FontAwesomeIcon icon={faCaretLeft} />
            </BtnPaginator>
            <Legend>
              &nbsp;Page {this.state.page + 1} / {this.state.pages || 1} of{' '}
              {this.state.documents && this.state.documents.length}{' '}
              {' documents'}
            </Legend>
            <BtnPaginator onClick={() => this.nextPage()}>
              <FontAwesomeIcon icon={faCaretRight} />
            </BtnPaginator>
            <br></br>
            <br></br>
            {this.props.roles.includes(
              'CoMo-Visualization-User-Admin-Document_Create'
            ) && (
              <Button variant="primary" onClick={this.setnewDocument}>
                New Document
              </Button>
            )}
          </SkfModal.Content>
        </SkfModal>
      </div>
    );
  }
}

const BtnPaginator = styled.div`
  background: lightgray;
  border-radius: 35px;
  width: 20px;
  text-align: center;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  float: left;
`;
const Legend = styled.div`
  margin-right: 5px;
  float: left;
`;
const IconContainer = styled(FontAwesomeIcon)`
  margin-right: 10px;
  font-size: 20px;
  color: white;
`;

export default ModalDocuments;
