import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControlLabel, Radio } from '@material-ui/core';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled, { StyledComponent } from 'styled-components';
import {
  Fault,
  FaultCategories,
  FaultImage,
  FaultsImages,
  FeedBackModel
} from '../../models/feedback';
import { getAssetIcon } from '../../selectors/getAssetSelector';
import {
  getFaultClassificationCategories,
  getFaultLocationCategories,
  getFeedback,
  getFeedbackFile,
  resetFault,
  resetFeedBackModel,
  saveFeedback,
  saveFeedbackImg
} from '../../services/feedBack.service';
import ButtonAtachFile from '../utils/buttonAtachFile/buttonAtachFile';
import { DropDown, DropDownValues } from '../utils/dropDown/DropDown';
import InputTextComponent from '../utils/input/inputText';
import SkfModal from './SkfModal';

const RowBotMargin = styled(Row)`
    margin-bottom: 30px;
`;

const FeedbackTitle = styled.h2`
  color: #7e7f83;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: auto;
  font-size: 32px;
  text-align: center;
  transform: translateY(18px);
  margin-bottom: 15px;
  padding-right: 23px;
  padding-bottom: 10px;
`;

interface StateProps {
  isOpen: boolean;
  isSaving: boolean;
  isLoading: boolean;
  feedBackModel: FeedBackModel;
  ImagefeedbackModel: FaultsImages;
  faultClassification: DropDownValues[];
  faultLocation: DropDownValues[];
  tmpImageFault: FaultImage[];
  sourceKey: string;
  sourceId: string;
  sourceType: string;
  isFeedBack: boolean;
  base64File: string;
}

export interface IFeedbackProps {
  recomendation: string;
  headerDescription: string;
  isFeedBack: boolean;
  eventCaseID: string;
  diagnosis: string;
  asset: string;
  assetStatus: number;
  saveFeedBack: Function;
  sourceKey: string;
  sourceType: string;
  sourceId: string;
  sourceFlag: string;
  companyId: string;
}

class FeedBack extends Component<IFeedbackProps, StateProps> {
  constructor(props: any) {
    super(props);
    this.setIsOpen = this.setIsOpen.bind(this);
    this.selectedReportFaultCorrect = this.selectedReportFaultCorrect.bind(
      this
    );
    this.selectedReportFaultNotFound = this.selectedReportFaultNotFound.bind(
      this
    );
    this.changeSelectedNotReason = this.changeSelectedNotReason.bind(this);
    this.changeFaultClasification = this.changeFaultClasification.bind(this);
    this.changeFaultLocation = this.changeFaultLocation.bind(this);
    this.changeSeverity = this.changeSeverity.bind(this);
    this.uploadFileOtherReason = this.uploadFileOtherReason.bind(this);
    this.changeSummary = this.changeSummary.bind(this);
    this.changebearingTotal = this.changebearingTotal.bind(this);
    this.changeBearingLastMaintanance = this.changeBearingLastMaintanance.bind(
      this
    );
    this.addNewFault = this.addNewFault.bind(this);
    this.saveFeedback = this.saveFeedback.bind(this);
    this.getImageFaults = this.getImageFaults.bind(this);
  }

  _isMounted = false;

  public readonly state: Readonly<StateProps> = {
    isOpen: false,
    feedBackModel: resetFeedBackModel(),
    isSaving: false,
    isLoading: true,
    isFeedBack: this.props.isFeedBack,
    faultClassification: [],
    faultLocation: [],
    sourceId: '',
    sourceType: '',
    sourceKey: '',
    tmpImageFault: [],
    ImagefeedbackModel: { faults: [], eventCaseId: '' },
    base64File: ''
  };

  render() {
    let { isOpen, isSaving, isLoading } = this.state;
    let { faultCorrectReason, faultCorrect } = this.state.feedBackModel;
    const bearingTotalRender = this.bearingTotalRender();
    let faultsRender = null;

    if (isOpen) {
      faultsRender = this.faultsRender();
    }

    return (
      <div>
        <FeedbackButton
          active={!this.state.isFeedBack}
          onClick={() => this.setIsOpen(true)}
        >
          Feedback
        </FeedbackButton>
        {isOpen && (
          <SkfModal isOpen={isOpen} handleClose={() => this.setIsOpen(false)}>
            <SkfModal.Header handleClose={() => this.setIsOpen(false)}>
              <FeedbackTitle>Event Feedback</FeedbackTitle>
            </SkfModal.Header>
            {(isSaving || isLoading) && (
              <LoadingContainer>
                <div className="spinner-border sfk_spinner" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </LoadingContainer>
            )}
            {!isSaving && !isLoading && (
              <SkfModal.Content>
                <Row className="justify-content-center">
                  <Col md="10" xs={{ order: 2, span: 12 }}>
                    <Row className="fontSizeTitleModal customPadding">
                      <Col
                        md={{ order: 1, span: 8 }}
                        xs={{ order: 2, span: 12 }}
                      >
                        Event Case{' '}
                        <b className="marginFeedbackTitle">
                          {this.props.eventCaseID}
                        </b>
                        {getAssetIcon(this.props.assetStatus, '1x')}
                      </Col>
                      <Col
                        md={{ order: 2, span: 4, offset: 0 }}
                        xs={{ order: 1, span: 10, offset: 2 }}
                        className="customGrey text-right small"
                      >
                        {this.props.headerDescription}
                      </Col>
                    </Row>
                    <Row className="customPadding">
                      <Col sm="5" xs="12">
                        <b>Indicated fault</b>
                        <p className="customPadding paddingRight">
                          {this.props.diagnosis}
                        </p>
                      </Col>
                      <Col sm="5" xs="12">
                        <b>Recommended action</b>
                        <p className="customPadding paddingRight">
                          {this.props.recomendation}
                        </p>
                      </Col>
                    </Row>
                    {this.props.asset !== 'Sensor' && (
                      <div>
                        <div className="customPadding">
                          <b>Was the reported fault found?</b>
                          <Row>
                            <Col md="2" sm="3" xs="5">
                              <FormControlLabel
                                value="Yes"
                                onChange={this.selectedReportFaultCorrect}
                                defaultChecked={true}
                                checked={this.state.feedBackModel.faultCorrect}
                                control={
                                  <Radio classes={{ checked: 'skf_blue' }} />
                                }
                                label="Yes"
                                labelPlacement="end"
                              />
                            </Col>
                            <Col md="2" sm="3" xs="5">
                              <FormControlLabel
                                value="No"
                                onChange={this.selectedReportFaultNotFound}
                                checked={!this.state.feedBackModel.faultCorrect}
                                defaultChecked={false}
                                control={
                                  <Radio classes={{ checked: 'skf_blue' }} />
                                }
                                label="No"
                                labelPlacement="end"
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                    {this.state.feedBackModel.faultCorrect === true && (
                      <div>
                        {faultsRender}
                        {bearingTotalRender}
                      </div>
                    )}
                    {this.state.feedBackModel.faultCorrect === false && (
                      <div>
                        <div className="customPadding">
                          <b>Reason</b>
                          <Row>
                            <Col lg="3" md="4" sm="5" xs="6">
                              <FormControlLabel
                                value="option1"
                                onChange={this.changeSelectedNotReason}
                                defaultChecked={false}
                                checked={faultCorrectReason}
                                control={
                                  <Radio classes={{ checked: 'skf_blue' }} />
                                }
                                label="No fault found"
                                labelPlacement="end"
                              />
                            </Col>
                            <Col lg="3" md="4" sm="5" xs="6">
                              <FormControlLabel
                                value="option2"
                                onChange={this.changeSelectedNotReason}
                                defaultChecked={true}
                                checked={!faultCorrectReason}
                                control={
                                  <Radio classes={{ checked: 'skf_blue' }} />
                                }
                                label="Other fault found"
                                labelPlacement="end"
                              />
                            </Col>
                          </Row>
                        </div>
                        <div>{faultsRender}</div>
                      </div>
                    )}
                  </Col>
                </Row>
              </SkfModal.Content>
            )}
            <SkfModal.Footer>
              <ActionButton
                variant={this.isValidFeedbackForm() ? 'primary' : 'default'}
                color="#0f58d6"
                onClick={this.saveFeedback}
                disabled={!this.isValidFeedbackForm()}
              >
                Save
              </ActionButton>
              <ActionButton
                variant="primary"
                onClick={() => this.setIsOpen(false)}
                disabled={isSaving}
              >
                Close
              </ActionButton>
            </SkfModal.Footer>
          </SkfModal>
        )}
      </div>
    );
  }

  toBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error('Failed to convert file to base64'));
        }
      };
      reader.onerror = error => reject(error);
    });
  }

  dataURLtoFile(dataurl: any, filename: string): File {
    let arr = dataurl.split(','),
      mime = arr[0].split(':')[1].split(';')[0],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  dataURLtoUint8Array(dataurl: any, filename: string): Uint8Array {
    let arr = dataurl.split(','),
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return u8arr;
  }

  uint8ArrayToBase64(uint8Array: Uint8Array): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        let binaryString = '';
        for (let i = 0; i < uint8Array.length; i++) {
          binaryString += String.fromCharCode(uint8Array[i]);
        }
        const base64String = btoa(binaryString);
        resolve(base64String);
      } catch (error) {
        reject(error);
      }
    });
  }

  getImageFaults(
    sourceId: string,
    companyId: string,
    uploadFiles: string,
    faultId: number
  ) {
    const _this = this;
    let uploadFilesurl = uploadFiles && uploadFiles.split('/');
    if (uploadFilesurl)
      getFeedbackFile(
        sourceId,
        companyId,
        uploadFilesurl[uploadFilesurl.length - 1],
        faultId
      ).then(function(response) {
        const promise = new Promise(resolve => {
          const a = new FileReader();
          a.onload = function() {
            resolve(a.result);
          };
          a.readAsDataURL(response);
        });

        promise.then(img => {
          const tmpObject: FaultImage = {
            faultId: faultId,
            fileName: uploadFiles,
            file: _this.dataURLtoUint8Array(img, uploadFiles),
            url: response
          };

          let tmpArray: FaultImage[] = [];

          if (
            _this.state.tmpImageFault &&
            _this.state.tmpImageFault.length > 0
          ) {
            tmpArray = _this.state.tmpImageFault;
            if (
              !_this.state.tmpImageFault.find(
                (e: { faultId: number }) => e.faultId.toString() === uploadFiles
              )
            ) {
              tmpArray.push(tmpObject);
            }
          } else {
            tmpArray.push(tmpObject);
          }
          // console.debug('state 0 ', tmpArray);

          _this.setState({
            tmpImageFault: tmpArray
          });
        });

        // console.debug('state 1 ', _this.state.tmpImageFault);
      });
  }

  faultsRender() {
    const _this = this;
    const length =
      this.state.feedBackModel &&
      this.state.feedBackModel.faults &&
      this.state.feedBackModel.faults.length;
    const faultsClassificationSelect =
      this.state.faultClassification &&
      this.state.faultClassification.map(dropdownValues => {
        return { value: dropdownValues.value, label: dropdownValues.label };
      });

    const faultsLocationSelect =
      this.state.faultLocation &&
      this.state.faultLocation.map(dropdownValues => {
        return { value: dropdownValues.value, label: dropdownValues.label };
      });

    const feedBackModel = this.state.feedBackModel;
    const tmpFault = this.state.ImagefeedbackModel;
    const renderFault = this.renderFault();

    if (
      !this.state.feedBackModel ||
      !this.state.feedBackModel.faults ||
      this.state.feedBackModel.faults.length === 0
    ) {
      return renderFault;
    }

    return (
      this.state.feedBackModel &&
      this.state.feedBackModel.faults &&
      this.state.feedBackModel.faults.map(function(faults, index) {
        let {
          classification,
          faultPosition,
          severity,
          feedBackComment,
          base64File,
          uploadFiles,
          faultId
        } = faults;
        let tmpFaultImg =
          tmpFault && tmpFault.faults.filter(e => e.faultId === faultId);

        let tmpEventCaseId = feedBackModel.eventCaseId;

        let tmpImageFault =
          _this.state.tmpImageFault &&
          _this.state.tmpImageFault.find(
            e => e.faultId.toString() === uploadFiles
          );

        if (
          !base64File &&
          !_this.state.base64File &&
          tmpImageFault &&
          tmpImageFault.file
        ) {
          _this
            .uint8ArrayToBase64(tmpImageFault.file)
            .then((base64: string) => _this.setState({ base64File: base64 }));
        }

        if (!base64File && _this.state.base64File)
          base64File = _this.state.base64File.split('base64,')[1];

        return (
          <React.Fragment key={faultId}>
            <RowBotMargin>
              <Col md="10" sm="12">
                {_this.props.asset !== 'Sensor' && (
                  <Row className="customPadding withoutpaddingLeft">
                    <Col md="4" sm="12">
                      <span
                        className="iconFeedBackPlus"
                        onClick={() => _this.removeNewFault(faultId)}
                      >
                        <FontAwesomeIcon size="2x" icon={faMinus} color="red" />
                      </span>
                      {(_this.state.feedBackModel.faultCorrect ||
                        !_this.state.feedBackModel.faultCorrectReason) && (
                        <DropDown
                          title="Classification"
                          options={faultsClassificationSelect}
                          cKey={index}
                          defaultValue={classification}
                          selected={_this.changeFaultClasification}
                        />
                      )}
                      {(!_this.state.feedBackModel.faultCorrectReason ||
                        _this.state.feedBackModel.faultCorrect) &&
                        !_this.state.feedBackModel.faults[index]
                          .classification && (
                          <span className="msgError">Required</span>
                        )}
                    </Col>
                    {(!_this.state.feedBackModel.faultCorrectReason ||
                      _this.state.feedBackModel.faultCorrect) && (
                      <>
                        <Col md="3" xs="9">
                          <DropDown
                            title="Location"
                            options={faultsLocationSelect}
                            cKey={index}
                            defaultValue={faultPosition}
                            selected={_this.changeFaultLocation}
                          />
                          {!_this.state.feedBackModel.faults[index]
                            .faultPosition && (
                            <span className="msgError">Required</span>
                          )}
                        </Col>
                        <Col md="2" xs="3">
                          <DropDown
                            title="Severity"
                            options={[
                              { value: 1, label: 'Red' },
                              { value: 2, label: 'Amber' }
                            ]}
                            cKey={index}
                            defaultValue={severity}
                            selected={_this.changeSeverity}
                          />
                          {!_this.state.feedBackModel.faults[index]
                            .severity && (
                            <span className="msgError">Required</span>
                          )}
                        </Col>
                        {uploadFiles != null && uploadFiles !== '' ? (
                          <React.Fragment>
                            <Col md="3" xs="12">
                              <ButtonAtachFile
                                cKey={index}
                                title="Change file"
                                updatedFile={_this.uploadFileOtherReason}
                                className="buttonFileContainer"
                              />
                            </Col>
                          </React.Fragment>
                        ) : (
                          <Col md="3" xs="12">
                            <ButtonAtachFile
                              cKey={index}
                              title="Upload File"
                              updatedFile={_this.uploadFileOtherReason}
                              className="buttonFileContainer"
                            />
                            <p>{uploadFiles}</p>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                )}
                <Row className="customPadding withoutpaddingLeft">
                  <Col md="12" xs="12" className="feedBackContainerIcon">
                    <InputTextComponent
                      titleText="Add Comment"
                      inputText={feedBackComment}
                      onChange={_this.changeSummary}
                      type="text"
                      cKey={index}
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                md="2"
                xs="9"
                className={
                  base64File !== '' || (tmpFaultImg && tmpFaultImg.length > 0)
                    ? 'blueBorder'
                    : ''
                }
              >
                {_this.props.asset !== 'Sensor' && base64File !== '' && (
                  <img
                    id={'b64_' + index}
                    alt=""
                    className="imgPreview"
                    itemID={'imgPreview' + faultId}
                    src={`data:image/jpeg;base64,${base64File}`}
                  ></img>
                )}

                {_this.props.asset !== 'Sensor' &&
                  !_this.state.tmpImageFault &&
                  _this.getImageFaults(
                    _this.props.sourceId,
                    _this.props.companyId,
                    uploadFiles,
                    faultId
                  )}

                {_this.props.asset !== 'Sensor' && base64File !== '' && (
                  <a
                    className="imgeTextcentered"
                    download={'imgPreview-' + tmpEventCaseId + '-' + faultId}
                    href={`data:image/jpeg;base64,${base64File}`}
                  >
                    Download
                  </a>
                )}

                {_this.props.asset !== 'Sensor' &&
                  tmpFaultImg &&
                  tmpFaultImg.length > 0 && (
                    <>
                      <img
                        id={'tfi_' + index}
                        alt=""
                        className="imgPreview"
                        itemID={'imgPreview' + faultId}
                        src={URL.createObjectURL(
                          new Blob([tmpFaultImg[0].file], {
                            type: 'image/jpeg'
                          })
                        )}
                      ></img>
                      <a
                        className="imgeTextcentered"
                        download={'imgPreview' + faultId}
                        href={URL.createObjectURL(
                          new Blob([tmpFaultImg[0].file], {
                            type: 'image/jpeg'
                          })
                        )}
                      >
                        Download
                      </a>
                    </>
                  )}
              </Col>
            </RowBotMargin>
            {_this.props.asset !== 'Sensor' &&
              length - 1 === index &&
              renderFault}
          </React.Fragment>
        );
      })
    );
  }

  renderFault() {
    return (
      <RowBotMargin>
        <Col md="10" sm="12">
          <Row className="customPadding withoutpaddingLeft">
            <Col md="12" xs="12">
              <span className="iconFeedBackMinus" onClick={this.addNewFault}>
                <FontAwesomeIcon size="2x" icon={faPlus} color="#0f58d6" />
              </span>
            </Col>
          </Row>
        </Col>
      </RowBotMargin>
    );
  }

  bearingTotalRender() {
    let { totalMileageBearing, lastMileageInterval } = this.state.feedBackModel;
    return this.props.asset &&
      this.props.asset.toLocaleLowerCase() !== 'sensor' ? (
      <RowBotMargin className="customPadding">
        <Col md="5" sm="12">
          <InputTextComponent
            type="number"
            titleText="Total mileage(km)"
            inputText={totalMileageBearing}
            onChange={this.changebearingTotal}
          />
        </Col>
        <Col md="5" sm="12">
          <InputTextComponent
            type="number"
            titleText="Last maintenance interval(km)"
            inputText={lastMileageInterval}
            onChange={this.changeBearingLastMaintanance}
          />
        </Col>
      </RowBotMargin>
    ) : null;
  }

  loadDropDown() {
    const _this = this;

    if (_this.props.asset === 'Sensor') return true;

    getFaultClassificationCategories(this.props.asset).then(
      (faultClassificationCategories: FaultCategories[]) => {
        faultClassificationCategories.sort((a, b) =>
          a.orderPosition > b.orderPosition ? 1 : -1
        );

        const tmpValues = faultClassificationCategories.map(x => {
          const tmp: DropDownValues = {
            value: x.orderPosition,
            label: x.description
          };
          return tmp;
        });
        _this.setState({
          faultClassification: tmpValues
        });
      }
    );

    getFaultLocationCategories(this.props.asset).then(
      (faultLocationCategories: FaultCategories[]) => {
        faultLocationCategories.sort((a, b) =>
          a.orderPosition > b.orderPosition ? 1 : -1
        );
        const tmpValues = faultLocationCategories.map(x => {
          const tmp: DropDownValues = {
            value: x.orderPosition,
            label: x.description
          };
          return tmp;
        });
        _this.setState({
          faultLocation: tmpValues
        });
      }
    );
  }

  loadDataFeedback() {
    const _this = this;
    getFeedback(this.props.sourceKey, this.props.sourceType).then(
      (feedBackFromBack: FeedBackModel) => {
        _this.setState({
          feedBackModel: feedBackFromBack
        });
        this.setState({
          feedBackModel: {
            ...this.state.feedBackModel
          },
          isLoading: false
        });
      }
    );
  }

  setIsOpen(newState: boolean) {
    this.setState({
      ImagefeedbackModel: { faults: [], eventCaseId: '' },
      isOpen: newState
    });
    if (newState) {
      this.setState({ isLoading: true });
      this.loadDropDown();
      this.loadDataFeedback();
    }
  }

  selectedReportFaultCorrect() {
    //By default we need at minimum 1 element
    const tmpFeedBackModel = this.state.feedBackModel;
    tmpFeedBackModel.faultCorrect = true;

    if (tmpFeedBackModel.faults && tmpFeedBackModel.faults.length === 0) {
      tmpFeedBackModel.faults.push(resetFault());
    }

    this.setState({
      feedBackModel: tmpFeedBackModel
    });
  }

  selectedReportFaultNotFound() {
    //We should clean the data for the hidden fields in this configuration
    const tmpFeedBackModel = this.state.feedBackModel;
    let faults: Fault[] = [];
    faults.push(resetFault());
    tmpFeedBackModel.faults = faults;
    tmpFeedBackModel.totalMileageBearing = 0;
    tmpFeedBackModel.lastMileageInterval = 0;
    tmpFeedBackModel.faultCorrect = false;

    this.setState({
      feedBackModel: tmpFeedBackModel
    });
  }

  changeSelectedNotReason() {
    const tmpFeedBackModel = this.state.feedBackModel;
    let faults: Fault[] = [];
    faults.push(resetFault());
    tmpFeedBackModel.faults = faults;
    tmpFeedBackModel.totalMileageBearing = 0;
    tmpFeedBackModel.lastMileageInterval = 0;
    tmpFeedBackModel.faultCorrectReason = !tmpFeedBackModel.faultCorrectReason;

    this.setState({
      feedBackModel: tmpFeedBackModel
    });
  }

  changeFaultClasification(value: string, index: number) {
    if (
      this.state.feedBackModel &&
      this.state.feedBackModel.faults &&
      this.state.feedBackModel.faults[index]
    ) {
      let feedBackModel = this.state.feedBackModel;
      feedBackModel.faults[index].classification = value;
      this.setState({
        feedBackModel: this.state.feedBackModel
      });
    }
  }

  changeFaultLocation(value: string, index: number) {
    if (
      this.state.feedBackModel &&
      this.state.feedBackModel.faults &&
      this.state.feedBackModel.faults[index]
    ) {
      let feedBackModel = this.state.feedBackModel;
      feedBackModel.faults[index].faultPosition = value;
      this.setState({
        feedBackModel: this.state.feedBackModel
      });
    }
  }

  changeSeverity(value: string, index: number) {
    if (
      this.state.feedBackModel &&
      this.state.feedBackModel.faults &&
      this.state.feedBackModel.faults[index]
    ) {
      let feedBackModel = this.state.feedBackModel;
      feedBackModel.faults[index].severity = value;
      this.setState({
        feedBackModel: this.state.feedBackModel
      });
    }
  }

  uploadFileOtherReason(
    fileByteArray: Uint8Array,
    index: number,
    fileName: string,
    filePreview: File | null
  ) {
    const feedbackModel = this.state.feedBackModel;
    const PrevImagefeedbackModel = this.state.ImagefeedbackModel;

    let newFault = feedbackModel.faults[index];

    if (newFault) {
      newFault.file = new Uint8Array(0);
      newFault.fileName = '';
      newFault.base64File = '';
      // newFault.previewImage = filePreview;
      feedbackModel.faults[index] = newFault;

      const faultToUpload: FaultImage = {
        faultId: newFault.faultId,
        file: fileByteArray,
        fileName: fileName,
        url: null
      };

      let faultsToUpload: FaultImage[] = [];
      faultsToUpload.push(faultToUpload);

      if (PrevImagefeedbackModel) {
        if (
          PrevImagefeedbackModel.faults.find(
            e => e.faultId === newFault.faultId
          )
        ) {
          let indexImage = PrevImagefeedbackModel.faults.findIndex(
            e => e.faultId === newFault.faultId
          );
          PrevImagefeedbackModel.faults[indexImage] = faultToUpload;
        } else {
          PrevImagefeedbackModel.faults.push(faultToUpload);
        }

        this.setState({
          ImagefeedbackModel: PrevImagefeedbackModel,
          feedBackModel: feedbackModel
        });
      } else {
        let ImageFeedback: FaultsImages = {
          eventCaseId: this.props.eventCaseID,
          faults: faultsToUpload
        };

        this.setState({
          ImagefeedbackModel: ImageFeedback,
          feedBackModel: feedbackModel
        });
      }
    }

    // let newFaultImage = ImagefeedbackModel.faults[index];
    // if (newFaultImage) {
    //     newFaultImage.file = file;
    //     newFaultImage.base64File = '';
    //     newFaultImage.fileName = fileName;
    //     newFaultImage.previewImage = filePreview;
    //     ImagefeedbackModel.faults[index] = newFaultImage;
    //     // this.setState({
    //     //     ImagefeedbackModel: ImagefeedbackModel
    //     // });
    // }

    // this.setState({
    //     ImagefeedbackModel: ImageFeedback,
    //     feedBackModel: feedbackModel
    // });

    //console.debug("state", this.state);
    /*
        this.setState({
          ...this.state.feedBackModel,
          fileName: fileName
        });
        */
  }

  changeSummary(text: string, index: number) {
    if (
      this.state.feedBackModel &&
      this.state.feedBackModel.faults &&
      this.state.feedBackModel.faults[index]
    ) {
      let feedBackModel = this.state.feedBackModel;
      feedBackModel.faults[index].feedBackComment = text;
      this.setState({
        feedBackModel: this.state.feedBackModel
      });
    }
  }

  changebearingTotal(number: number, cKey: number) {
    this.setState({
      feedBackModel: {
        ...this.state.feedBackModel,
        totalMileageBearing: number
      }
    });
  }

  changeBearingLastMaintanance(number: number, cKey: number) {
    this.setState({
      feedBackModel: {
        ...this.state.feedBackModel,
        lastMileageInterval: number
      }
    });
  }

  removeNewFault(faultId: number) {
    const feedBackModel = this.state.feedBackModel;
    let fault: Fault[] = [];
    if (feedBackModel.faults.length <= 1) {
      if (feedBackModel.faults.length !== 0) {
        const faultId = feedBackModel.faults[0].faultId;
        let newFault = resetFault();
        newFault.faultId = faultId + 1;
        fault.push(newFault);
      } else {
        fault.push(resetFault());
      }
    } else {
      feedBackModel.faults.forEach((z: Fault) => {
        if (faultId !== z.faultId) {
          fault.push(z);
        }
      });
    }
    feedBackModel.faults = fault;
    this.setState({
      feedBackModel: feedBackModel
    });
  }

  addNewFault() {
    let lastFaultId = 0;
    const feedBackModel = this.state.feedBackModel;
    let fault = resetFault();
    if (!feedBackModel.faults) {
      feedBackModel.faults = [];
    }
    feedBackModel.faults.forEach((z: Fault) => {
      if (z.faultId > lastFaultId) {
        lastFaultId = z.faultId;
      }
    });
    fault.faultId = lastFaultId + 1;
    feedBackModel.faults.push(fault);
    this.setState({
      feedBackModel: feedBackModel
    });
  }

  saveFeedback() {
    const _this = this;

    if (!_this.isValidFeedbackForm()) {
      this.setState({
        isSaving: false
      });
      return;
    }

    let model = this.state.feedBackModel;
    model.eventCaseId = this.props.eventCaseID;
    model.sourceId = this.props.sourceId;
    model.sourceFlag = this.props.sourceFlag;
    model.sourceKey = this.props.sourceKey;
    model.sourceType = this.props.sourceType;
    model.companyId = this.props.companyId;

    this.setState({
      feedBackModel: model,
      isSaving: true,
      isFeedBack: true
    });

    saveFeedback(this.state.feedBackModel)
      .then(data => {
        const modelImage = this.state.ImagefeedbackModel;
        if (modelImage && modelImage.faults && modelImage.faults.length > 0) {
          for (let i = 0; i < modelImage.faults.length; i += 1) {
            if (modelImage.faults[i].file) {
              //console.debug("modelResponse", data.slice(15,-2));
              const form = new FormData();
              const feedbackId = data.feedBackId;
              // const feedbackId = modelImage.feedBackId;
              const FaultID = modelImage.faults[i].faultId;
              const faultFile = modelImage.faults[i].file;

              form.append('SourceId', this.props.sourceId);
              form.append('FeedBackID', feedbackId);
              form.append('FaultID', FaultID.toString());
              form.append('CompanyId', this.props.companyId);
              form.append('SourceType', this.props.sourceType);
              form.append(
                'File',
                new Blob([faultFile]),
                modelImage.faults[i].fileName
              );
              //form.append("File", faultFile);

              saveFeedbackImg(form)
                .then(() => {
                  _this.props.saveFeedBack(true);
                  _this.setIsOpen(false);
                  this.setState({
                    isSaving: false
                  });
                })
                .catch(() => {
                  //console.debug("response", e);
                  this.props.saveFeedBack(false);
                  _this.setIsOpen(false);
                  this.setState({
                    isSaving: false
                  });
                });
            }
          }
        } else {
          _this.props.saveFeedBack(true);
          _this.setIsOpen(false);

          this.setState({
            isSaving: false
          });
        }
      })
      .catch(() => {
        //console.debug("response", e);
        _this.setIsOpen(false);

        this.props.saveFeedBack(false);
        this.setState({
          isSaving: false
        });
      });
  }

  isValidFeedbackForm() {
    const _this = this;

    if (_this.props.asset === 'Sensor') return true;

    let validateMandatoryFields = false;

    //(Was the reported fault found?=Yes) OR (Reason=No fault found)
    validateMandatoryFields =
      _this.state.feedBackModel.faultCorrect ||
      !_this.state.feedBackModel.faultCorrectReason;
    if (!validateMandatoryFields) return true;

    //Valid classification
    if (
      !_this.state.feedBackModel.faults.every(
        fault =>
          fault.classification != null &&
          fault.classification !== undefined &&
          fault.classification !== ''
      )
    )
      return false;

    //Valid faultPosition
    if (
      !_this.state.feedBackModel.faults.every(
        fault =>
          fault.faultPosition != null &&
          fault.faultPosition !== undefined &&
          fault.faultPosition !== ''
      )
    )
      return false;

    //Valid severity
    if (
      !_this.state.feedBackModel.faults.every(
        fault =>
          fault.severity != null &&
          fault.severity !== undefined &&
          fault.severity !== ''
      )
    )
      return false;

    //If all the the previous validations are ok then return true
    return true;
  }
}

export default FeedBack;

interface IActionButtonProps {
  variant?: 'primary' | 'default';
}

const ActionButton: StyledComponent<
  'button',
  any,
  IActionButtonProps,
  never
> = styled.button`
  border-radius: 3px;
  width: 9rem;
  padding: 0.5rem;
  ${(props: IActionButtonProps) => {
    if (props.variant === 'primary')
      return `background-color: rgb(14, 88, 214);
      border: none;
      cursor: pointer;
      color: white;`;
    else
      return `background-color: white;
      color: rgb(146, 153, 164);
      border: 1px rgb(146, 153, 164) solid;
      `;
  }}
`;

interface IFeedbackButtonProps {
  active?: boolean;
}

const FeedbackButton: StyledComponent<
  'button',
  any,
  IFeedbackButtonProps,
  never
> = styled.button`
  background-color: ${(props: IFeedbackButtonProps) =>
    props.active ? '#0f58d6' : '#7e7f83'};
  border: 0;
  min-height: 38px;
  cursor: pointer;
  outline: 0px auto -webkit-focus-ring-color !important;
  color: #ffffff;
  border-radius: 3px;
  padding: 0 10px;
`;
