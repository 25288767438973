import { dashboardService } from '../services/dashboard.service';
import { VehicleLocation } from '../actions/trainDashboardMapAsyncAction';
import moment from 'moment';
import { Train } from '../models/train';
import { toast } from 'react-toastify';

export interface TrainDashboardApiService {
  getTrains: () => Promise<Train[]>;
  getClientsTrains: (client: string, level: any) => Promise<Train[]>;
  getLocations: () => Promise<VehicleLocation[]>;
}

export async function getTrains(): Promise<Train[]> {
  let result: Train[] = [];
  const replayArray = await dashboardService.getCountAllKPIsByCustomerByLevel();
  replayArray.map((replay: any) => {
    if (replay.totalVehicles) {
      replay.levelPanel.typeVehicle = 'Trains';
      replay.levelPanel.total = replay.totalVehicles;
      result = result.concat(replay.levelPanel as Train);
    } else if (replay.totalCarriages) {
      replay.levelPanel.typeVehicle = 'Carriages';
      replay.levelPanel.total = replay.totalCarriages;
      result = result.concat(replay.levelPanel as Train);
    } else if (replay.totalBogies) {
      replay.levelPanel.typeVehicle = 'Bogies';
      replay.levelPanel.total = replay.totalBogies;
      result = result.concat(replay.levelPanel as Train);
    } else {
      toast.error(
        '😲 Error: ' + replay.response.status + ', conctact your admin ',
        {
          position: 'bottom-center',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        }
      );
      return [] as Train[];
    }
  });
  return result as Train[];
}

export async function getClientsTrains(
  client: string,
  level: any
): Promise<Train[]> {
  let result: Train[] = [];
  const replayArray = await dashboardService.GetAllKPIsByCustomerByLevel(
    client,
    level
  );
  replayArray.map((replay: any) => {
    if (replay.totalVehicles) {
      replay.vehiclesPanel.map((e: Train) => (e.typeVehicle = 'Trains'));
      result = result.concat(replay.vehiclesPanel as Train[]);
    } else if (replay.totalCarriages) {
      replay.carriagesPanel.map((e: Train) => (e.typeVehicle = 'Carriages'));
      result = result.concat(replay.carriagesPanel as Train[]);
    } else if (replay.totalBogies) {
      replay.bogiesPanel.map((e: Train) => (e.typeVehicle = 'Bogies'));
      result = result.concat(replay.bogiesPanel as Train[]);
    } else {
      toast.error(
        '😲 Error: ' + replay.response.status + ', conctact your admin ',
        {
          position: 'bottom-center',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        }
      );
      return [] as Train[];
    }
  });
  result = result.filter(e => !e.isAxleboxesFake);
  return result as Train[];
}

export async function getLocations(): Promise<VehicleLocation[]> {
  const client = sessionStorage.getItem('client');
  const isIMx = sessionStorage.getItem('isIMx');
  const level = sessionStorage.getItem('typeVehicle');
  const endDate = moment(new Date()).format('YYYY-MM-DD');
  const startDate = moment(new Date())
    .subtract(7, 'days')
    .format('YYYY-MM-DD');

  if (client && level && level !== '') {
    const vehicleLocations = await dashboardService.getLocationGpsByCustomer(
      startDate,
      endDate,
      level,
      client,
      isIMx
    );
    return vehicleLocations as VehicleLocation[];
  }
  return [] as VehicleLocation[];
}

export function createTrainDashboardApiService(): TrainDashboardApiService {
  return {
    getTrains,
    getClientsTrains,
    getLocations
  };
}
