import React from 'react';
import { connect } from 'react-redux';
import { DashboardContainer } from '../../../../containers/dashboardContainerTrains';

import AES from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import { history } from '../../../../helpers';
import ErrorBoundary from '../../../../App/ErrorBoundary';
// import { RecordPageView } from '../../../../App/AwsRum';
class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.intervalId = undefined;
    props.changeMenu('dashboard');
  }

  componentDidMount() {
    if (!this.intervalId)
      this.intervalId = window.setInterval(() => {
        this.checkLogin();
      }, 1000);
  }

  componentWillMount() {
    this.props.showHeader(true);
    this.props.setShowOpctionsHeader(false);
  }

  render() {
    const {
      showOpctionsHeader,
      showOpctionCustomerHeader,
      setShowOpctionsHeader,
      setShowOpctionCustomerHeader,
      hasDashboardToVehicle
    } = this.props;
    const userName = sessionStorage.getItem('userName');

    return (
      <div>
        <DashboardContainer
          isLoaded={true}
          showHeader={this.props.showHeader}
          showOpctionsHeader={showOpctionsHeader}
          showOpctionCustomerHeader={showOpctionCustomerHeader}
          setShowOpctionsHeader={setShowOpctionsHeader}
          setShowOpctionCustomerHeader={setShowOpctionCustomerHeader}
          userName={userName}
          hasDashboardToVehicle={hasDashboardToVehicle}
        ></DashboardContainer>
        <ErrorBoundary></ErrorBoundary>
      </div>
    );
  }

  getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  logOut = () => {
    sessionStorage.setItem('loggedIn', false);
    sessionStorage.setItem('idToken', '');
    sessionStorage.setItem('tokenType', '');
    sessionStorage.setItem('refreshToken', '');
    sessionStorage.setItem('userName', '');
    sessionStorage.setItem('expiresIn', '');
    this.props.showHeader(false);
    history.push('/login');
  };

  checkLogin = () => {
    let decryptedData = sessionStorage.getItem('idToken');
    let expiresInSKF = sessionStorage.getItem('expiresIn');
    if (expiresInSKF === '' || !expiresInSKF)
      expiresInSKF = this.getCookie('expiresInSKF');
    if (decryptedData === '' || !decryptedData) {
      let chyperTken =
        this.getCookie('tokenSKF1') + this.getCookie('tokenSKF2');
      if (chyperTken === '') this.logOut();
      var bytes = AES.decrypt(chyperTken, process.env.REACT_APP_SECRET);
      decryptedData = enc
        .stringify(bytes)
        .replace('"', '')
        .replace('"', '');

      sessionStorage.setItem('loggedIn', true);
      sessionStorage.setItem(
        'idToken',
        'Bearer ' + decryptedData.replace('"', '').replace('"', '')
      );
      sessionStorage.setItem('expiresIn', expiresInSKF);
    }

    if (Date.now() < expiresInSKF && decryptedData && decryptedData != '') {
    } else {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
      this.logOut();
    }
  };
}

const mapDispatchToProps = dispatch => ({
  changeMenu: menu => dispatch({ type: 'CHANGE_MENU', menu }),
  getHierarchy: () => dispatch({ type: 'GET_HIERARCHY' }),
  hasDashboardToVehicle: payload =>
    dispatch({ type: 'handledTreeSelected ', payload })
});

function mapStateToProps(state) {
  return { isLoading: state.fleet.isLoading };
}

const connectedDashboardPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPage);
export { connectedDashboardPage as DashboardPage };
