import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  TimeScale,
  Tooltip,
  Legend,
  Interaction,
  RadialLinearScale
} from 'chart.js';
import styled from 'styled-components';
import moment from 'moment';
import { faSpinner, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import { Radar } from 'react-chartjs-2';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';
Chart.register(
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  RadialLinearScale,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  CrosshairPlugin
);

Interaction.modes.interpolate = Interpolate;

const ComponentContainer = styled.div`
  .react-datepicker__year-read-view--down-arrow {
    margin-left: 25px;
    transform: translateY(-5px);
  }

  .react-datepicker-wrapper:first-child input {
    margin-left: 0;
  }

  .react-datepicker__input-container > input {
    -webkit-appearance: none;
    border: 0px;
    border-bottom: 1px solid black;
    margin-left: 15px;
    background-color: #ffffff;
    padding: 9px 0px 5px 0px;
  }
`;

const ShowDetailBtn = styled.div`
  padding: 0 20px 5px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const NoRenderBtn = styled.div`
  min-height: 1px;
`;

const ArrowContainer = styled(FontAwesomeIcon)`
  transform: translateX(-15px);
  pointer-events: none;
`;

export class BearingTemperatureSensor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalSensors: [],
      sensorsToShow: [],
      sensorsToHide: [],
      showLabel: false,
      labelValue: '',
      change: false
    };
    this.myRef2 = React.createRef();
  }

  toHistory = date => {
    this.props.referenceToShow(date);
  };

  datasetKeyProvider = () => {
    return Math.random();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.sensorsToShow || nextProps.sensorsToShow?.length === 0) {
      return nextProps;
    }

    if (prevState.sensorsToShow !== nextProps.sensorsToShow) {
      //console.debug("nextProps", nextProps);
      //if (nextProps.thresholds.length > 0) {
      /** Calcuate stepsize for grid lines */
      let stepsize = nextProps.thresholds
        ? nextProps.thresholds[0][1] - nextProps.thresholds[0][0]
        : 10;
      /** Thresholds for sensors */
      let upperThreshold = nextProps.thresholds
        ? nextProps.thresholds[0][1]
        : 0;
      let lowerThreshold = nextProps.thresholds
        ? nextProps.thresholds[0][0]
        : 0;
      /** min value and max data value */
      if (stepsize === 0) stepsize = 6;
      let minForChart = Math.min(...Object.values(nextProps.sensorsToShow));
      let maxForChart = Math.max(...Object.values(nextProps.sensorsToShow));
      minForChart = Math.ceil(minForChart / stepsize) * stepsize;
      maxForChart = Math.ceil(maxForChart / stepsize) * stepsize;
      /** calculate top and min grid line for the chart */
      let chartMax =
        maxForChart > upperThreshold ? maxForChart + stepsize : upperThreshold;

      let chartMin = minForChart - stepsize * 2;

      let stepNumbers = (chartMax - chartMin) / stepsize;
      stepNumbers = Math.floor(stepNumbers);

      stepNumbers = Math.floor(stepNumbers);

      let ticks =
        chartMin !== lowerThreshold && chartMax !== upperThreshold
          ? [chartMin, lowerThreshold, upperThreshold, chartMax]
          : chartMin !== lowerThreshold && chartMax === upperThreshold
          ? [chartMin, lowerThreshold, upperThreshold]
          : chartMin === lowerThreshold && chartMax !== upperThreshold
          ? [lowerThreshold, upperThreshold, chartMax]
          : [lowerThreshold, upperThreshold];

      ticks = [0, 10, 20, 40, 60, 70];

      if (stepNumbers < 5) stepNumbers = 5;
      let fontColorArr = [...Array(stepNumbers + 1).keys()].map(
        (value, index) => {
          if (stepsize * index + chartMin >= lowerThreshold) {
            if (stepsize * index + chartMin >= upperThreshold) {
              return '#ffffff';
            } else {
              return '#ffffff';
            }
          }
          return '#000000';
        }
      );

      let backdropArr = [...Array(stepNumbers + 1).keys()].map(
        (value, index) => {
          if (stepsize * index + chartMin >= lowerThreshold) {
            if (stepsize * index + chartMin >= upperThreshold) {
              return 'rgb(183, 28, 28)';
            } else {
              return 'rgba(255, 193, 7)';
            }
          }
          return 'rgb(255, 255, 255)';
        }
      );

      // console.debug("ticks" , ticks);

      let fontColorArrTicks = [...Array(ticks?.length).keys()].map(
        (value, index) => {
          // console.debug("index", index);
          if (index < ticks?.length - 2) {
            return '#000000';
          }
          return '#ffffff';
        }
      );

      let backdropArrTicks = [...Array(ticks?.length).keys()].map(
        (value, index) => {
          if (index === ticks?.length - 1) {
            return 'rgb(183, 28, 28)';
          }
          if (index === ticks?.length - 2) {
            return 'rgba(255, 193, 7)';
          }
          return 'rgb(255, 255, 255)';
        }
      );
      fontColorArr = chartMin < 0 ? fontColorArrTicks : fontColorArr;
      backdropArr = chartMin < 0 ? backdropArrTicks : backdropArr;
      fontColorArr = [
        '#ffffff',
        '#000000',
        '#000000',
        '#000000',
        '#000000',
        '#000000'
      ];
      backdropArr = [
        '#ffffff',
        '#ffffff',
        '#ffffff',
        '#ffffff',
        '#ffffff',
        '#ffffff'
      ];

      let customMaxTicks = 7;
      let customTickSize = (chartMax - chartMin) / customMaxTicks;
      let customTickSizeRounded = 10 * Math.ceil(customTickSize / 10);
      let customTicks = [];
      let customTickColor = [];
      let customTickFontColor = [];
      let customTickBackColor = [];
      let customUpperFlag = false;
      let customLowerFlag = false;

      for (
        var iterator = chartMax;
        iterator >= chartMin;
        iterator -= customTickSizeRounded
      ) {
        if (upperThreshold <= iterator && !customUpperFlag) {
          customUpperFlag = true;
          if (upperThreshold < iterator) {
            customTicks.push(iterator);
            customTicks.push(upperThreshold);
            customTickColor.push('lightgray');
            customTickColor.push('rgb(183, 28, 28)');
            customTickFontColor.push('#000000');
            customTickBackColor.push('#FFFFFF');
            customTickFontColor.push('#000000');
            customTickBackColor.push('#FFFFFF');
          } else {
            customTicks.push(iterator);
            customTickColor.push('rgb(183, 28, 28)');
            customTickFontColor.push('#000000');
            customTickBackColor.push('#FFFFFF');
          }
        } else if (lowerThreshold >= iterator && !customLowerFlag) {
          customLowerFlag = true;
          if (lowerThreshold > iterator) {
            customTicks.push(lowerThreshold);
            customTicks.push(iterator);
            customTickColor.push('rgba(255, 193, 7)');
            customTickColor.push('lightgray');
            customTickFontColor.push('#000000');
            customTickBackColor.push('#FFFFFF');
            customTickFontColor.push('#000000');
            customTickBackColor.push('#FFFFFF');
          } else {
            customTicks.push(iterator);
            customTickColor.push('rgba(255, 193, 7)');
            customTickFontColor.push('#000000');
            customTickBackColor.push('#FFFFFF');
          }
        } else {
          customTicks.push(iterator);
          customTickColor.push('lightgray');
          customTickFontColor.push('#000000');
          customTickBackColor.push('#FFFFFF');
        }
      }

      if (customTicks[customTickFontColor?.length - 1] !== chartMin) {
        customTicks.push(chartMin);
        customTickColor.push('lightgray');
        customTickFontColor.push('#000000');
        customTickBackColor.push('#FFFFFF');
      }

      if (customTickFontColor !== null) {
        customTickFontColor =
          customTickFontColor?.length > 0 &&
          customTickFontColor.slice(0, customTickFontColor?.length - 1);
        customTickFontColor.push('#FFFFFF');
        customTickColor =
          customTickColor?.length > 0 &&
          customTickColor.slice(0, customTickColor?.length - 1);
      }

      customTickFontColor.reverse();
      customTickColor.reverse();
      customTicks.reverse();
      customTicks = customTicks.map(e => ({
        value: e,
        label: e.toString()
      }));

      return {
        totalSensors: nextProps.totalSensors,
        sensorsToShow: nextProps.sensorsToShow,
        sensorsToHide: nextProps.sensorsToHide,
        labels: nextProps.labels,
        lowerThreshold,
        upperThreshold,
        stepsize: stepsize,
        chartMin,
        chartMax,
        stepNumbers,
        backdropArr,
        fontColorArr,
        ticks: ticks,
        customTicks: customTicks,
        customTickColor: customTickColor,
        customTickFontColor: customTickFontColor,
        customTickBackColor: customTickBackColor
      };
    }
    return prevState;
  }

  getTotalSensors = () => {
    if (this.props.totalSensors) {
      return this.props.totalSensors;
    } else {
      return 0;
    }
  };

  getTotalLabels = () => {
    if (this.props.labels) {
      var tmpLabels = [];
      this.props.labels.map((value, index) => {
        tmpLabels.push(value.sensorFunctionalLocationSerialNumber);
      });

      return tmpLabels;
    } else {
      return new Array(this.getTotalSensors()?.length).fill('');
    }
  };

  getTotalLabels = () => {
    if (this.props.labels) {
      var tmpLabels = [];
      this.props.labels.map((value, index) => {
        tmpLabels.push(value.sensorFunctionalLocationSerialNumber);
      });

      return tmpLabels;
    } else {
      return new Array(this.getTotalSensors()?.length).fill('');
    }
  };

  getShowLabel = () => {
    return this.props.showLabel;
  };

  getLabelValue = () => {
    return this.props.labelValue;
  };

  sensorsSelected = data => {
    const datasetsColors = [];
    const realdata = data.getDatasetMeta(0);
    if (realdata) {
      let show = this.state.sensorsToShow;
      let hide = this.state.sensorsToHide;
      let numSensors = 0;
      if (this.state.totalSensors) {
        numSensors = this.state.totalSensors?.length;
      }
      for (let i = 0; i < numSensors; i++) {
        if (typeof realdata.data[i] !== 'undefined') {
          if (show[i] > 0 && hide[i] === 0) {
            datasetsColors.push('#0f58d6');
          } else {
            datasetsColors.push('#9a9a9a');
          }
        }
      }
    }
    data.getDatasetMeta(0)._dataset.pointBackgroundColor = datasetsColors;
  };

  createData = () => {
    const labels = this.getTotalLabels();
    const data = {
      datasetKeyProvider: 'bearingTemperature',
      labels: labels,
      datasets: [
        {
          label: 'Value',
          borderColor: 'rgba(0,0,0,0)',
          pointBackgroundColor: '#0f58d6',
          pointBorderColor: 'rgba(0,0,0,0)',
          pointRadius: 4,
          data: this.state.totalSensors,
          fill: false
        }
      ]
    };

    return data;
  };

  render() {
    const plugin = {
      beforeDatasetsUpdate: (chart, args, options) => {
        this.sensorsSelected(chart);
      }
    };

    const options = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        },

        crosshair: false
      },

      scales: {
        r: {
          grid: {
            color: this.state.customTickColor,
            circular: true,
            lineWidth: 1.2,
            borderDash: [10, 5],
            borderDashOffset: 10
          },

          afterBuildTicks: scale => {
            // scale.ticks = this.state.chartMin < 0
            //   ? [this.state.chartMin, this.state.lowerThreshold, this.state.chartMax]
            //   : scale.ticks;
            scale.ticks = this.state.customTicks;
            return;
          },
          pointLabels: {
            display: false
          },
          min: this.state.chartMin || 0,
          max: this.state.chartMax || 100,
          ticks: {
            display: true,

            beginAtZero: false
          },
          angleLines: {
            display: false
          }
        }
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
              label += ': ';
            }
            label += Math.round(tooltipItem.yLabel * 100) / 100;
            label += ' °C';
            return label;
          },
          title: (tooltipItem, data) => {
            return data.labels[tooltipItem[0].index];
          }
        }
      }
    };
    const data = this.createData();

    if (this.props.isLoading) {
      return (
        <div className="fleet-graph">
          <div
            className="w-100 fleet-graph-title"
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <p className="page-box-map-main-title">Max Bearing Temperature</p>

            <div>
              <DatePicker
                selected={this.props.selected}
                onChange={e => this.props.handleDayChange(e)}
                showYearDropdown
                dateFormatCalendar="MMMM"
                scrollableYearDropdown
                yearDropdownItemNumber={15}
                dateFormat="dd/MMMM/yyyy"
                maxDate={moment().toDate()}
              />
              <ArrowContainer icon={faChevronDown} />
            </div>
          </div>
          <div className="heightSensor vCenterGraph">
            <FontAwesomeIcon icon={faSpinner} size="2x" spin className="" />
          </div>
        </div>
      );
    }

    if (
      this.props.sensorsToShow == null ||
      this.props.sensorsToShow?.length < 1
    ) {
      return (
        <div className=" fleet-graph">
          <div
            className="w-100 fleet-graph-title"
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <p className="page-box-map-main-title">Max Bearing Temperature</p>
            <ComponentContainer>
              <DatePicker
                selected={this.props.selected}
                onChange={e => this.props.handleDayChange(e)}
                showYearDropdown
                dateFormatCalendar="MMMM"
                scrollableYearDropdown
                yearDropdownItemNumber={15}
                dateFormat="dd/MMMM/yyyy"
                maxDate={moment().toDate()}
              />
              <ArrowContainer icon={faChevronDown} />
            </ComponentContainer>
          </div>
          <div className="heightSensor vCenterGraph">
            <span className=" fleet-datetime-group">No data</span>
          </div>
          {this.props.showDetailBtn ? (
            <ShowDetailBtn>
              <button
                onClick={() => this.toHistory(this.props.selected)}
                className="btn btn-link"
              >
                Show History
              </button>
            </ShowDetailBtn>
          ) : (
            <ShowDetailBtn style={{ height: '20px' }}>
              <NoRenderBtn></NoRenderBtn>
            </ShowDetailBtn>
          )}
        </div>
      );
    }

    return (
      <div className=" fleet-graph">
        <div
          className="w-100 fleet-graph-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <p className="page-box-map-main-title">Max Bearing Temperature</p>
          <ComponentContainer>
            <DatePicker
              selected={this.props.selected}
              onChange={e => this.props.handleDayChange(e)}
              showYearDropdown
              dateFormatCalendar="MMMM"
              scrollableYearDropdown
              yearDropdownItemNumber={15}
              dateFormat="dd/MMMM/yyyy"
              maxDate={moment().toDate()}
            />
            <ArrowContainer icon={faChevronDown} />
          </ComponentContainer>
        </div>
        <div className="heightSensor">
          <Radar
            ref={this.myRef2}
            id="radarTemperature"
            //  datasetKeyProvider={this.datasetKeyProvider}
            plugins={[plugin]}
            data={data}
            height={'285px;'}
            options={options}
          />
        </div>

        <ShowDetailBtn>
          {this.props.showDetailBtn ? (
            <button
              onClick={() => this.toHistory(this.props.selected)}
              className="btn btn-link"
            >
              Show History
            </button>
          ) : (
            <NoRenderBtn></NoRenderBtn>
          )}
        </ShowDetailBtn>
      </div>
    );
  }
}
