/** This component contains the table without the big header, only the sub-header and the rows */
import React, { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TableBody from './tableBody';
import { getEventtKeySelector } from '../../../selectors/getEventtSelector';

const ComponentContainer = styled.div`
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
  min-width: 1250px;
`;
const TitleText = styled.th`
  font-weight: 700;
  text-align: left;
  padding: 5px;
  padding-left: 0;
`;

const DataContainer = styled(TitleText)`
  text-align: center;
`;

const RecommendedActionsContainer = styled(TitleText)`
  width: 25%;
  text-align: center;
`;

const TableContainer = styled.table`
  tbody:last-child {
    border-bottom: 1px solid #dee2e6;
  }
`;

const TableHead = styled.tr`
  background-color: #f6f6f6;
  border-top: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6;
  border-left: 3px solid
    ${props => (props.borderColor ? props.borderColor : '')};
`;
/** This function is used to sort the events based on they level of asset status */
const sortEventsByAssetStatus = (a, b) => {
  return a.status - b.status;
};

const sortEventsByDate = (a, b) => {
  return moment.utc(b.alarmTimestamp).diff(moment.utc(a.alarmTimestamp));
};
const Table = ({
  events,
  alarmLevel,
  changeActionStatus,
  headerDescription,
  saveFeedBack,
  onlyOpen,
  eventFocus
}) => {
  events.sort((a, b) => sortEventsByDate(a, b));

  events.sort((a, b) => sortEventsByAssetStatus(a, b));

  const borderColor =
    (alarmLevel === 1 && '#cb0000') ||
    (alarmLevel === 2 && '#cb0000') ||
    (alarmLevel === 3 && '#ffca10') ||
    '#dee2e6';

  return (
    <ComponentContainer>
      <TableContainer className="table fullTable">
        <thead>
          <TableHead borderColor={borderColor}>
            <DataContainer className="align-middle">Event</DataContainer>
            <DataContainer className="align-middle">Asset</DataContainer>
            <DataContainer className="align-middle">Action</DataContainer>
            <DataContainer className="align-middle">Sensor IMEI</DataContainer>
            <DataContainer className="align-middle">
              Measurement type
            </DataContainer>

            <DataContainer />
            <DataContainer className="align-middle">Status</DataContainer>
            <RecommendedActionsContainer className="align-middle hiddenResponsive">
              Recommended actions
            </RecommendedActionsContainer>
            <DataContainer className="align-middle">Reported on</DataContainer>
            <DataContainer className="align-middle" hidden={true}>
              Closed on
            </DataContainer>
            <DataContainer className="align-middle">
              Action Status
            </DataContainer>
            <DataContainer colSpan="2"></DataContainer>
            <DataContainer colSpan="1"></DataContainer>
          </TableHead>
        </thead>
        {events.map((axleboxEvent, index) => {
          const emailSubject = getEventtKeySelector(axleboxEvent);
          if (onlyOpen && axleboxEvent.status === 2)
            return <Fragment key={index}></Fragment>;
          return (
            <TableBody
              temperature={axleboxEvent.temperature_External}
              key={index}
              sourceId={emailSubject}
              asset={axleboxEvent.assetType}
              identifier={axleboxEvent.renewedIMEI}
              measurementType={axleboxEvent.measurementType}
              createdOn={moment(axleboxEvent.alarmTimestamp).format('lll')}
              createdBy={axleboxEvent.createdBy}
              recommendedActions={axleboxEvent.recommendation}
              eventId={`collapsable${index}${axleboxEvent.axleboxReference}${axleboxEvent.carriageReference}${axleboxEvent.eventId}`}
              assetStatus={axleboxEvent.assetStatus}
              status={axleboxEvent.status}
              isFeedBack={axleboxEvent.isFeedBack}
              actionStatus={axleboxEvent.actionStatus}
              sourceKey={axleboxEvent.sourceKey}
              sourceType={axleboxEvent.sourceType}
              sourceFlag={axleboxEvent.sourceFlag}
              companyId={axleboxEvent.companyId}
              symptoms={axleboxEvent.symptoms}
              diagnosis={axleboxEvent.diagnosis}
              changeActionStatus={changeActionStatus}
              // emailData={`mailto:germany.rdc@skf.com?subject=${emailSubject}|${axleboxEvent.train_ID}|${axleboxEvent.vehicle_Nickname}|${axleboxEvent.axleboxID}&body=%0A%0A---------Data%20from%20SKF%20Insight%E2%84%A2Rail---------%0A%0AFleet:%20${axleboxEvent.train_Fleet}%0ATrain:%20${axleboxEvent.train_ID}%0AVehicle:%20${axleboxEvent.vehicle_Nickname}%0APosition:%20${axleboxEvent.position}%0A%0AWheel%20Set:%20${axleboxEvent.wheelSet}%0AWheel%20Diameter:%20${axleboxEvent.wheelDiameter}%0AAxlebox%20ID:%20${axleboxEvent.axleboxID}%0ABrand%20%26%20Designation:%20${axleboxEvent.brandDesignation}%0ABearing%20Serial%20Number:%20${axleboxEvent.bearingSN}%0A%0ASensor%20ID:%20${axleboxEvent.identifier}`}
              emailData={`mailto:germany.rdc@skf.com?subject=${emailSubject}|${axleboxEvent.train_ID}|${axleboxEvent.vehicle_Nickname}|${axleboxEvent.axleboxID}&body=%0A%0A---------Data%20from%20SKF%20Insight%E2%84%A2Rail---------%0A%0AFleet:%20${axleboxEvent.train_Fleet}%0ATrain:%20${axleboxEvent.train_ID}%0AVehicle:%20${axleboxEvent.vehicle_Nickname}%0A%0AWheel%20Set:%20${axleboxEvent.wheelSet}%0AWheel%20Diameter:%20${axleboxEvent.wheelDiameter}%0AAxlebox%20ID:%20${axleboxEvent.axleboxID}%0ABrand%20%26%20Designation:%20${axleboxEvent.brandDesignation}%0ABearing%20Serial%20Number:%20${axleboxEvent.bearingSN}%0A%0ASensor%20ID:%20${axleboxEvent.identifier}`}
              headerDescription={headerDescription}
              saveFeedBack={saveFeedBack}
              feedBackId={axleboxEvent.feedBackId}
              // emailData={`mailto:germany.rdc@skf.com?subject=${axleboxEvent.caseNoPrefix}-${axleboxEvent.eventCaseID}-${axleboxEvent.reportID}|${axleboxEvent.train_ID}|${axleboxEvent.vehicle_Nickname}|${axleboxEvent.axleboxID}&body=%0A%0A---------Data%20from%20SKF%20Insight%E2%84%A2Rail---------%0A%0AFleet:%20${axleboxEvent.train_Fleet}%0ATrain:%20${axleboxEvent.train_ID}%0AVehicle:%20${axleboxEvent.vehicle_Nickname}%0APosition:%20${axleboxEvent.position}%0A%0AWheelSet:%20${axleboxEvent.wheelSet}%0AWheelDiameter:%20${axleboxEvent.wheelDiameter}%0AAxleboxID:%20${axleboxEvent.axleboxID}%0ABrand%20%26%20Designation:%20${axleboxEvent.brandDesignation}%0ABearingSerialNumber:%20${axleboxEvent.bearingSN}%0A%0ASensorID:%20${axleboxEvent.identifier}%0AFirmwareVersion:%201.1.1743%0AAxleboxLocationAngle:%20-67%0AAxleboxOrientation:%20-2%0AInstallationDate:%2029%2F06%2F2017%0A`}
            />
          );
        })}
      </TableContainer>
    </ComponentContainer>
  );
};

Table.propTypes = {
  changeActionStatus: PropTypes.func.isRequired,
  saveFeedBack: PropTypes.func.isRequired,
  /** Events contains all the events from the corresponding Axlebox */
  events: PropTypes.array.isRequired,
  /** Events contains all the events from the corresponding Axlebox */
  alarmLevel: PropTypes.number.isRequired,
  /** Data needed to send Feedback email */
  headerDescription: PropTypes.string.isRequired
};
// Table.defaultProps = {};

export default Table;
