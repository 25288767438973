import { sendStatsSuccess, sendStatsFailure } from '../actions/statsActions';
export async function getToken() {
  const cognitoDomain = process.env.REACT_APP_DOMINIO_TOKEN;
  const cognitoUrlToken = `${cognitoDomain}/oauth2/token`;
  const clientId = process.env.REACT_APP_API_STADISTIC_CLIENTID;
  const clientSecret = process.env.REACT_APP_API_STADISTIC_SECRET;
  const requestBody = new URLSearchParams();
  requestBody.append('grant_type', 'client_credentials');
  const basicAuthString = btoa(`${clientId}:${clientSecret}`);
  try {
    const response = await fetch(cognitoUrlToken, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${basicAuthString}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: requestBody
    });
    if (!response.ok) {
      throw new Error('Error obtaining the token');
    }
    const responseBody = await response.json();
    return responseBody.access_token;
  } catch (error) {
    console.error('Error obtaining the token:', error);
    return null;
  }
}
const sendStatistics = async (accessToken, data) => {
  try {
    if (!accessToken) {
      throw new Error('Missing access token');
    }
    console.log('Sending request with token:', accessToken);
    const apiUrl = process.env.REACT_APP_API_STADISTIC_URL;
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }
    console.log('Post request successful!');
  } catch (error) {
    console.error('Error sending statistics:', error.message);
    throw error;
  }
};
export const handleSendStats = title => {
  return async (dispatch, getState) => {
    const {
      statsSent
    } = getState().stats;
    if (statsSent[title]) {
      return;
    }
    try {
      const accessToken = await getToken();
      if (!accessToken) {
        throw new Error('Access token not found');
      }
      const loginDate = new Date().toLocaleString('en-US', {
        timeZone: 'UTC',
        hour12: false
      }).replace(/,/g, '');
      const loginTime = new Date().toLocaleTimeString('en-US', {
        timeZone: 'UTC',
        hour12: false
      });
      const statisticsData = {
        userName: sessionStorage.getItem('userName'),
        userEmail: sessionStorage.getItem('userEmail'),
        loginDate: loginDate,
        loginTime: loginTime,
        latitude: 0,
        longitude: 0,
        action: 'Button access at ' + title,
        actionParams: '',
        uuidSession: sessionStorage.getItem('idToken').replace('Bearer ', ''),
        toolName: 'CoMo'
      };
      await sendStatistics(accessToken, statisticsData);
      dispatch(sendStatsSuccess(title));
    } catch (error) {
      console.error('Error in handleSendStats:', error.message);
      dispatch(sendStatsFailure(error.message));
    }
  };
};