import React, { Fragment, PureComponent } from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { Switch } from '@blueprintjs/core';
import MainFooter from '../footer/mainFooter';
import { sendContactForm } from '../../services/contactUs.service';
import moment from 'moment';
// import { ErrorBoundary } from '../../App/AwsRum';

const ComponentContainer = styled.div`
  background-color: #fefefe;
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
  padding: 30px;
  margin: 20px;
  min-height: calc(100vh - 135px);

  .popup-content {
    padding: 0 !important;
    border: 0;
  }
`;

const WidgetContainer = styled.div`
  background-color: #fefefe;
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
  padding: 30px;
  width: 100%;
`;

const ModalContainer = styled(WidgetContainer)`
  text-align: center;
`;

const TitleContainer = styled.div`
  width: 100%;
  font-size: 32px;
  border-bottom: 1px solid grey;
`;

const FormContainer = styled.div`
  margin-top: 10px;
  display: flex;
`;

const LeftContainer = styled.div`
  width: 33%;
`;

const RightContainer = styled.div`
  flex-grow: 2;
  margin-left: 20px;
`;

const EmailContainer = styled.div``;

const HelpYouContainer = styled.div`
  margin-top: 10px;
`;

const InputContainer = styled.input`
  border-radius: 5px;
  border: 1px solid #e4e4e5;
  outline: none;
  width: 100%;
  padding: 5px;
  background-color: #dedede;
  cursor: default;
`;

const SelectContainer = styled.select`
  border-radius: 5px;
  outline: none;
  border: 1px solid #e4e4e5;
  width: 100%;
  padding: 5px;
  cursor: pointer;
`;

const SwitchContainer = styled(Switch)`
  margin-top: 20px;
`;

const TextArea = styled.textarea`
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e4e4e5;
  min-height: 300px;
`;

const InputtitlesContainer = styled.div`
  font-weight: 700;
  font-size: 14px;
`;

const TextModal = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 15px;
`;

const BlueButton = styled.button`
  border: 2px solid rgb(15, 88, 214);
  border-radius: 2px;
  background-color: transparent;
  color: rgb(15, 88, 214);
  padding: 5px 20px;
  font-weight: 700;
  transition: background-color, color 0.5s;
`;

class ContactUsMain extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sendCopyEmail: false,
      selectHelp: 'iNeedSupport',
      comment: '',
      username: sessionStorage.getItem('userName'),
      idToken: sessionStorage.getItem('idToken'),
      responseData: false,
      isModalOpen: false
    };
  }

  handleSelectChange = e => {
    this.setState({ selectHelp: e.target.value });
  };

  handleTextAreaChange = e => {
    this.setState({ comment: e.target.value });
  };

  handleSwitchChange = () => {
    this.setState(prevState => ({ sendCopyEmail: !prevState.sendCopyEmail }));
  };

  handleSubmit = async () => {
    const helpType = this.state.selectHelp;
    const comment = this.state.comment;
    const sendCopy = (this.state.sendCopyEmail && 'YES') || 'NO';
    const auth = {
      alias: this.state.username,
      auth_token: this.state.idToken
    };
    const dateTime = moment().format('YYYY/MM/DD HH:mm:ss');
    let response;
    try {
      response = await sendContactForm({
        helpType,
        comment,
        sendCopy,
        auth,
        dateTime
      });
    } catch (error) {
      this.setState({ isModalOpen: true, responseData: false });
    }

    this.setState({ isModalOpen: true, responseData: response.data });
  };

  render() {
    const { sendCopyEmail, selectHelp, username } = this.state;
    return (
      <Fragment>
        <ComponentContainer>
          <TitleContainer>Contact us</TitleContainer>
          <FormContainer>
            <LeftContainer>
              <EmailContainer>
                <InputtitlesContainer>Email</InputtitlesContainer>
                <InputContainer type="text" readOnly value={`${username}`} />
              </EmailContainer>
              <HelpYouContainer>
                <InputtitlesContainer>
                  How can we help you?
                </InputtitlesContainer>
                <SelectContainer
                  name="selectHelp"
                  id="selectHelp"
                  onChange={e => this.handleSelectChange(e)}
                  value={selectHelp}
                >
                  <option value="iNeedSupport">I need support</option>
                  <option value="iWantToGiveFeedback">
                    I want to give feedback
                  </option>
                </SelectContainer>
              </HelpYouContainer>
              <div>
                <SwitchContainer
                  label="Send a copy to my email"
                  checked={sendCopyEmail}
                  onChange={this.handleSwitchChange}
                />
              </div>
            </LeftContainer>
            <RightContainer>
              <InputtitlesContainer>Write your comment</InputtitlesContainer>
              <TextArea onChange={this.handleTextAreaChange} />
              <BlueButton onClick={() => this.handleSubmit()}>Send</BlueButton>
            </RightContainer>
          </FormContainer>
          <Popup modal open={this.state.isModalOpen}>
            {close => (
              <ModalContainer>
                <TextModal>
                  {this.state.responseData ? (
                    'Your comment was successfully saved'
                  ) : (
                    <div>
                      Sorry for the inconvenience.
                      <br /> but there was an error saving your comment
                    </div>
                  )}
                </TextModal>
                <BlueButton
                  onClick={() => {
                    this.setState({ isModalOpen: false });
                    return close;
                  }}
                >
                  Close
                </BlueButton>
              </ModalContainer>
            )}
          </Popup>
        </ComponentContainer>

        <MainFooter />
      </Fragment>
    );
  }
}

export default ContactUsMain;
