import React, { useState, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { DashboardFilter } from './dashboardFilter';
import MainFooter from '../footer/mainFooter';
import { DashboardTrainsPanel } from './dashboardTrainsPanel';
import { DashboardAssetsPanel } from './dashboardAssetsPanel';
import { DashboardFleetHistory } from './dashboardFleetHistory';
import { DashboardProps } from './dashboard.props';
import { StatusHeader } from '../../models/statusHeader';

const Dashboard = ({
  addTrainToFilter,
  hashDashboardToVehicle,
  clearFilter,
  removeFilterTrain,
  trains,
  isFetching,
  selectedTrains,
  totalRed,
  totalAmber,
  totalBearingRed,
  totalBearingAmber,
  totalWheelRed,
  totalWheelAmber,
  totalSensorAmber,
  totalGearBoxAmber,
  totalGearBoxRed,
  totalTractionMotorAmber,
  totalTractionMotorRed,
  typeVehicle,
  locations,
  isFetchingLocations,
  userName,
  bearingsFilter,
  wheelsFilter,
  motorFilter,
  gearFilter,
  sensorsFilter,
  totalFilter,
  IMxRail,
  treeData
}: DashboardProps) => {
  const onClickTrain = ({ vehicleName }: any) => addTrainToFilter(vehicleName);
  const [hierarchyFleetData] = useState([]);

  //This filter is only created to avoid errors for calling StatusHeader component, maybe this filter could
  //be other property in this Dashboard component.
  const [setFilter] = useState('');

  function handledTreeSelected(event: any) {
    let result: any = trains.find((e: any) => e.reference === event.value);
    if (!result) {
      const node: any = treeData.find((e: any) => e.key === event.value);
      const parentNode: any = treeData.find((e: any) => e.key === node.pId);
      const grandParentNode: any = treeData.find(
        (e: any) => e.key === parentNode.pId
      );

      const resultParent: any = trains.find(
        (e: any) => e.reference === parentNode.key
      );
      if (resultParent) result = resultParent;
      else {
        const resultGrandParent: any = trains.find(
          (e: any) => e.reference === grandParentNode.key
        );
        if (resultGrandParent) result = resultGrandParent;
      }
    }

    if (result) onClickTrain({ vehicleName: result.reference });
  }

  function filterLocationsBySelected(locations: any) {
    let pointsGPS = locations;
    if (selectedTrains.length > 0) {
      pointsGPS = locations.filter((e: any) =>
        selectedTrains.includes(e.reference)
      );
    }
    return pointsGPS;
  }

  return (
    <Fragment>
      <StatusHeader
        totalFilteredTrains={
          selectedTrains.length ? selectedTrains.length : trains.length
        }
        totalRed={totalRed}
        showNumbers={true}
        title={'Status'}
        totalAmber={totalAmber}
        setFilter={setFilter}
        typeVehicle={typeVehicle}
        userName={userName}
        showOrderBy={false}
        totalFilter={totalFilter}
      ></StatusHeader>

      <Container
        fluid={true}
        className="DASHBOARD main-background dFlex customHeightDashboard"
      >
        {/* Dropdown menu */}
        <Row className="align-items-center marginDashboard">
          <DashboardFilter
            removeFilterTrain={removeFilterTrain}
            clearAllFilters={clearFilter}
            filteredTrains={selectedTrains}
            treeData={treeData}
          ></DashboardFilter>
        </Row>
        <Row className="resetMargins flex1 main-background">
          <Col lg={IMxRail ? 8 : 6} xs={IMxRail ? 8 : 7} className="dFlex">
            <DashboardAssetsPanel
              isLoading={false}
              totalBearingAmber={totalBearingAmber}
              totalBearingRed={totalBearingRed}
              totalSensorAmber={totalSensorAmber}
              totalWheelAmber={totalWheelAmber}
              totalWheelRed={totalWheelRed}
              totalGearBoxAmber={totalGearBoxAmber}
              totalGearBoxRed={totalGearBoxRed}
              valuesSelected={selectedTrains}
              totalTractionMotorAmber={totalTractionMotorAmber}
              totalTractionMotorRed={totalTractionMotorRed}
              bearingsFilter={bearingsFilter}
              handledTreeSelected={handledTreeSelected}
              treeData={treeData}
              hierarchyFleetData={hierarchyFleetData}
              wheelsFilter={wheelsFilter}
              motorFilter={motorFilter}
              gearFilter={gearFilter}
              sensorsFilter={sensorsFilter}
              IMxRail={IMxRail}
            ></DashboardAssetsPanel>
            <DashboardTrainsPanel
              selectedTrains={selectedTrains}
              typeVehicle={typeVehicle}
              hasDashboardToVehicle={hashDashboardToVehicle}
              onClickTrain={onClickTrain}
              trains={trains}
            ></DashboardTrainsPanel>
          </Col>
          <Col lg={IMxRail ? 4 : 6} xs={IMxRail ? 4 : 5} className="d-flex">
            <DashboardFleetHistory
              filteredLocations={filterLocationsBySelected(locations)}
              isLoading={isFetchingLocations}
              handleClickOnMarker={addTrainToFilter}
            ></DashboardFleetHistory>
          </Col>
        </Row>
        <MainFooter />
      </Container>
    </Fragment>
  );
};

Dashboard.defaultProps = {
  IMxRail: false,
  trains: [],
  isFetching: false,
  selectedTrains: [],
  totalRed: 0,
  totalAmber: 0,
  totalBearingRed: 0,
  totalBearingAmber: 0,
  totalWheelRed: 0,
  totalWheelAmber: 0,
  totalSensorAmber: 0,
  userName: ''
};

export { Dashboard };
