import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AES from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { cleanHierarchy } from '../actions';
import { history } from '../helpers';
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      submitted: false,
      validated: false
    };
    this.intervalId = undefined;
  }

  getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  checkLogIn = () => {
    function SetLogout() {
      sessionStorage.setItem('client', '');
      sessionStorage.setItem('loggedIn', 'false');
      sessionStorage.setItem('idToken', '');
      sessionStorage.setItem('refreshToken', '');
      sessionStorage.setItem('tokenType', '');
      sessionStorage.setItem('userName', '');
      sessionStorage.setItem('expiresIn', '');
      document.cookie = 'tokenSKF1=';
      document.cookie = 'tokenSKF2=';
    }

    this.props.dispatch(cleanHierarchy());

    //Validating expire time
    const expiresInSKF = this.getCookie('expiresInSKF');
    if (!(expiresInSKF && expiresInSKF > Date.now())) {
      SetLogout();
      this.intervalId = window.setInterval(() => {
        this.checkLogIn();
      }, 1000);
      return;
    }

    //Validating AES token
    const chyperTken =
      this.getCookie('tokenSKF1') + this.getCookie('tokenSKF2');
    if (!(chyperTken && chyperTken !== '')) {
      SetLogout();
      this.intervalId = window.setInterval(() => {
        this.checkLogIn();
      }, 1000);
      return;
    }

    //Decrypting AES token to JWT token
    const bytes = AES.decrypt(chyperTken, process.env.REACT_APP_SECRET);
    const decryptedData = enc.stringify(bytes);

    //Validating JWT token
    if (!(decryptedData && decryptedData !== '')) {
      SetLogout();
      this.intervalId = window.setInterval(() => {
        this.checkLogIn();
      }, 1000);
      return;
    }

    const jwtToken = decryptedData.replace('"', '').replace('"', '');
    sessionStorage.setItem('loggedIn', true);
    sessionStorage.setItem('idToken', 'Bearer ' + jwtToken);
    sessionStorage.setItem('expiresIn', expiresInSKF);

    // Split the token into its three parts
    const [headerBase64, payloadBase64, signature] = jwtToken.split('.');
    // Decode the header and payload from base64
    const header = JSON.parse(atob(headerBase64));
    const payload = JSON.parse(atob(payloadBase64));

    sessionStorage.setItem('userName', payload['cognito:username']);
    sessionStorage.setItem('userEmail', payload.email);

    clearInterval(this.intervalId);
    this.intervalId = undefined;
    history.push('/dashboard');
  };

  takeUrl = () => {
    const queryString = window.location.search;
    const queryStringSplitedHostAndParameters = queryString.split('?tokenSKF=');

    if (queryStringSplitedHostAndParameters.length > 1) {
      const queryStringSplitedParameters = queryStringSplitedHostAndParameters[1].split(
        '&expiresInSKF='
      );
      if (queryStringSplitedParameters.length > 1) {
        let token = queryStringSplitedParameters[0];
        // Set the first cookie with the first 2000 characters
        document.cookie = 'tokenSKF1=' + token.substring(0, 2000);
        // Set the second cookie with the remaining characters
        document.cookie = 'tokenSKF2=' + token.substring(2000);

        let expired = queryStringSplitedParameters[1];
        document.cookie = 'expiresInSKF=' + expired;
      }
    }
  };

  componentDidMount() {
    this.takeUrl();
    this.checkLogIn();
  }

  handleSubmit = e => {
    let url = process.env.REACT_APP_LOGIN_URL;
    window.location.replace(url);
  };

  handleLogout = () => {
    sessionStorage.setItem('loggedIn', false);
    sessionStorage.setItem('idToken', '');
    sessionStorage.setItem('tokenType', '');
    sessionStorage.setItem('refreshToken', '');
    sessionStorage.setItem('userName', '');
    sessionStorage.setItem('expiresIn', '');
    this.forceUpdate();
  };

  render() {
    const { loginState } = this.props;
    const { alert } = this.props;

    const { username, password, submitted, validated } = this.state;
    const lsloggedin = sessionStorage.getItem('loggedIn');
    const lsidToken = sessionStorage.getItem('idToken');
    let isLoggedIn = lsidToken && lsloggedin;
    return (
      <div>
        <div className="logo" />
        <div className="full authBG">
          <div className="loginBox">
            <div className="loginTitle">
              <span className="SKFH1">Welcome to SKF</span>
              <br />
              <span className="SKFH1">Rail CoMo Visualization</span>
            </div>
            {isLoggedIn ? (
              <div>
                <div>You are already logged in</div>
                <button onClick={this.handleLogout}>Logout</button>
              </div>
            ) : (
              <div className=" centerText customMargin">
                <Button
                  className="btn btn-primary buttonlogin"
                  onClick={this.handleSubmit}
                >
                  Go Log in{' '}
                  {loginState && <FontAwesomeIcon icon={faSpinner} spin />}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const user = state.authentication.user;
  const { alert } = state;
  const error = user && user.message && user.statusCode === 401;
  let errMessage = error && user.message;
  return {
    loginState: loggingIn,
    alert,
    error,
    errMessage
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
