import { SEND_STATS_SUCCESS, SEND_STATS_FAILURE } from '../actions/statsActions';
const initialState = {
  statsSent: {},
  error: null
};
const statsReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SEND_STATS_SUCCESS:
      return {
        ...state,
        statsSent: {
          ...state.statsSent,
          [action.payload]: true
        }
      };
    case SEND_STATS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
export default statsReducer;