import PropTypes from 'prop-types';
import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { history } from '../../helpers';
import { PrivateRoute } from '../../components/PrivateRoute';
import { DashboardPage } from '../../Home/Dashboard/DashboardContainer/Dashboard/DashboardPage';
import { LoginPage } from '../../LoginPage';
import NotFound from '../NotFound';
import { TopNav } from '../../components/TopNav';
import { ReportsMain } from '../../components/reports/reportsMain';
import { HistoryMain } from '../../components/history/historyMain';
import { UserManagement } from '../../components/userManagement/userManagement';
import ContactUs from '../../components/contactUs/contactUsMain';
import { AboutUsMain } from '../../components/aboutUs/aboutUsMain';
import DummyComp from '../../components/reports/dummyData/dummyComp';
import { forceUpdateFleet } from './../../actions/index';
import { VehicleMain } from './../../components/vehiclePage/vehicleMain';
import IdleTimer from 'react-idle-timer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { ErrorBoundary } from '../AwsRum';
class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeader: false,
      showOpctionsHeader: false,
      showOpctionCustomerHeader: false
    };
    this.IdleTimeLogOut = this.IdleTimeLogOut.bind(this);
  }

  clickUserManagement = () => {
    history.push('/usermanagement');
  };

  clickContactUs = () => {
    history.push('/contact-us');
  };

  clickAboutUs = () => {
    history.push('/about-us');
  };

  showHeader = hide => {
    this.setState({
      showHeader: hide
    });
  };

  setShowOpctionsHeader = hide => {
    this.setState({
      showOpctionsHeader: hide
    });
  };

  setShowOpctionCustomerHeader = hide => {
    this.setState({
      showOpctionCustomerHeader: hide
    });
  };

  closeSideBar = () => {
    this.props.openSidebar(false);
  };

  IdleTimeLogOut() {
    if (
      sessionStorage.getItem('loggedIn') === 'true' &&
      Date.now() > new Date(sessionStorage.getItem('expiresIn')).getTime()
    ) {
      this.clickLogout();
    }
  }

  clickLogout() {
    this.props.openSidebar(false);

    sessionStorage.setItem('client', '');
    sessionStorage.setItem('loggedIn', 'false');
    sessionStorage.setItem('idToken', '');
    sessionStorage.setItem('refreshToken', '');
    sessionStorage.setItem('tokenType', '');
    sessionStorage.setItem('userName', '');
    sessionStorage.setItem('expiresIn', '');
    document.cookie = 'tokenSKF1=';
    document.cookie = 'tokenSKF2=';

    this.props.forceUpdateFleet();
    history.push('/login');
  }

  componentDidMount() {
    var searchParams = new URLSearchParams(window.location.href);

    const eventSelected = searchParams.get('event');
    const companyP = searchParams.get('company');
    const levelP = searchParams.get('level');
    this.setState({ eventSelected, company: companyP, level: levelP });
    if (companyP) sessionStorage.setItem('client', companyP);
    if (levelP) sessionStorage.setItem('typeVehicle', levelP);
    if (companyP) {
      this.showHeader(true);
      this.setShowOpctionsHeader(true);
      this.setShowOpctionCustomerHeader(true);
    }
  }

  render() {
    const { isSideBarOpen, activePath } = this.props;
    const { showOpctionsHeader, showOpctionCustomerHeader } = this.state;
    return (
      <IdleTimer timeout={100} onIdle={this.IdleTimeLogOut}>
        <div className="full" id="App">
          <Router history={history}>
            <div className="full" id="page-wrap">
              {this.state.showHeader && (
                <TopNav
                  showOpctionsHeader={showOpctionsHeader}
                  showOpctionCustomerHeader={showOpctionCustomerHeader}
                  setShowOpctionsHeader={this.setShowOpctionsHeader}
                  setShowOpctionCustomerHeader={
                    this.setShowOpctionCustomerHeader
                  }
                  activePath={activePath}
                />
              )}
              <Switch>
                <PrivateRoute
                  activeClassName="active"
                  showHeader={this.showHeader}
                  showOpctionsHeader={showOpctionsHeader}
                  showOpctionCustomerHeader={showOpctionCustomerHeader}
                  setShowOpctionsHeader={this.setShowOpctionsHeader}
                  setShowOpctionCustomerHeader={
                    this.setShowOpctionCustomerHeader
                  }
                  exact
                  path="/"
                  component={DashboardPage}
                />
                <PrivateRoute
                  activeClassName="active"
                  showHeader={this.showHeader}
                  showOpctionsHeader={showOpctionsHeader}
                  showOpctionCustomerHeader={showOpctionCustomerHeader}
                  setShowOpctionsHeader={this.setShowOpctionsHeader}
                  setShowOpctionCustomerHeader={
                    this.setShowOpctionCustomerHeader
                  }
                  exact
                  path="/dashboard"
                  component={DashboardPage}
                />

                <Route
                  path="/login"
                  render={props => (
                    <LoginPage showHeader={this.showHeader} {...props} />
                  )}
                />

                <PrivateRoute
                  exact
                  activeClassName="active"
                  path="/about-us"
                  component={AboutUsMain}
                />

                {this.state.showHeader && (
                  <PrivateRoute
                    exact
                    activeClassName="active"
                    path="/usermanagement"
                    component={UserManagement}
                  />
                )}
                {showOpctionsHeader && [
                  <PrivateRoute
                    exact
                    activeClassName="active"
                    path="/reports"
                    key="ReportsMain"
                    component={ReportsMain}
                  />,
                  <PrivateRoute
                    exact
                    activeClassName="active"
                    path="/history"
                    key="HistoryMain"
                    component={HistoryMain}
                  />,
                  <PrivateRoute
                    exact
                    activeClassName="active"
                    path="/contact-us"
                    key="ContactUs"
                    component={ContactUs}
                  />,
                  <PrivateRoute
                    exact
                    activeClassName="active"
                    path="/testingstuff"
                    key="DummyComp"
                    component={DummyComp}
                  />,
                  <PrivateRoute
                    exact
                    activeClassName="active"
                    path="/vehicle-detail"
                    key="VehicleMain"
                    component={VehicleMain}
                  />
                ]}
                <Route path="*" component={NotFound} />
                <Route path="/error" component={NotFound} />
              </Switch>
            </div>
          </Router>

          <div className={`sidebarMenu ${isSideBarOpen ? 'openMenu' : ''}`}>
            <div className="topSideBar">
              <Row>
                <Col className="text-right">
                  <span
                    onClick={this.closeSideBar}
                    style={{ cursor: 'pointer', marginRight: '9px' }}
                    className="fontMin"
                  >
                    Close X
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className="text-right">
                  <ul className="profileMenu">
                    <li
                      className="firstProfileItem cursorPointer"
                      onClick={() => this.clickUserManagement()}
                    >
                      User account
                    </li>
                    <li
                      className="cursorPointer"
                      onClick={() => this.clickContactUs()}
                    >
                      Contact us
                    </li>
                    <li
                      className="cursorPointer"
                      onClick={() => this.clickAboutUs()}
                    >
                      About
                    </li>
                    <li
                      className="cursorPointer"
                      onClick={() => this.clickLogout()}
                    >
                      Logout
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <ToastContainer />
      </IdleTimer>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openSidebar: menu => dispatch({ type: 'OPEN_SIDEBAR', menu }),
    forceUpdateFleet: () => dispatch(forceUpdateFleet())
  };
};

function mapStateToProps(state) {
  let activePath = 'dashboard';
  let open = false;
  if (state.menu && state.menu.activePath) {
    activePath = state.menu.activePath.menu;
  }
  if (state.menu && state.menu.open) {
    open = state.menu.open.menu;
  }
  return {
    activePath,
    isSideBarOpen: open
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

Navigation.propTypes = {
  activePath: PropTypes.any,
  isSideBarOpen: PropTypes.any,
  openSidebar: PropTypes.any
};

Navigation.defaultProps = {
  activePath: null,
  isSideBarOpen: null,
  openSidebar: null
};
