import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PageTutorial from '../components/pageTutorial';
import dashboardIcon from '../assets/img/nav/ic-dashboard.png';
import reportIcon from '../assets/img/nav/ic-report.png';
import historyIcon from '../assets/img/nav/ic-history.png';
import fleetIcon from '../assets/img/nav/ic-fleets.png';
import customerIcon from '../assets/img/nav/globe.png';
import { history } from '../helpers';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faBell } from '@fortawesome/free-solid-svg-icons';
import './TopNav.css';

import { eventService } from '../services/event.service';

import {
  faExclamationCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as Logo } from '../assets/img/skf-logo-white.svg';

const IconContainer = styled(FontAwesomeIcon)`
  margin-right: 10px;
  font-size: 20px;
  color: white;
`;

const Bubble = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #cb3234;
  margin-top: -35px;
  margin-left: 10px;
  text-align: center;
`;

const BubbleText = styled.span`
  font-weight: bold;
  color: white;
  font-size: 12px;
  text-align: center;
`;

const items = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    icon: dashboardIcon,
    url: '/dashboard'
  },
  {
    id: 'fleet',
    label: 'Fleets',
    icon: fleetIcon,
    url: '/vehicle-detail'
  },
  {
    id: 'reports',
    label: 'Reports',
    icon: reportIcon,
    url: '/reports'
  },
  {
    id: 'history',
    label: 'History',
    icon: historyIcon,
    url: '/history'
  },
  {
    id: 'customers',
    label: 'Customers',
    icon: customerIcon,
    url: '/'
  }
];

class TopNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: null,
      events: []
    };
    this.clickLogout = this.clickLogout.bind(this);
  }

  componentDidMount() {
    window.onbeforeunload = function(event) {
      sessionStorage.setItem('client', '');
    };

    eventService
      .getEventsNotifications()
      .then(e => this.setState({ events: e }));
  }

  isUserBTAdmin = () => {
    let idToken = sessionStorage.getItem('idToken').replace('Bearer ', '');
    if (idToken === '') return false;

    // Split the token into its three parts
    const [headerBase64, payloadBase64, signature] = idToken.split('.');
    // Decode the header and payload from base64
    const payload = JSON.parse(atob(payloadBase64));
    const cognitoGroups = payload['cognito:groups'];
    const isAdmin = cognitoGroups.indexOf('BT-Users-Admin') !== -1;

    return isAdmin;
  };

  clickAdminPortal = () => {
    let idToken = sessionStorage.getItem('idToken');

    let url =
      process.env.REACT_APP_ADMIN_PORTAL_URL +
      '/#id_token=' +
      idToken.replace('Bearer ', '') +
      '&switchTo=BT-Users-Admin';
    window.open(url, '_blank');
  };

  getEvents = () => {
    const customer = sessionStorage.getItem('client');

    if (customer && customer !== '')
      return (
        this.state.events &&
        this.state.events.filter(e => e.companyName === customer)
      );
    else return this.state.events;
  };

  setEvents = events => {
    this.setState({ events });
  };

  clickLogout() {
    sessionStorage.setItem('client', '');
    sessionStorage.setItem('loggedIn', 'false');
    sessionStorage.setItem('idToken', '');
    sessionStorage.setItem('refreshToken', '');
    sessionStorage.setItem('tokenType', '');
    sessionStorage.setItem('userName', '');
    sessionStorage.setItem('expiresIn', '');
    document.cookie = 'tokenSKF1=';
    document.cookie = 'tokenSKF2=';

    window.location.replace(process.env.REACT_APP_LOGIN_URL + '?logout=true');
  }

  openSidebar() {
    this.props.openSidebar(true);
  }

  openCustomers() {
    this.props.openCustomers('dashboard');
  }

  onFieldChange(event) {
    // for a regular input field, read field name and value from the event
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    this.props.onChange(fieldName, fieldValue);
  }

  clickUserManagement = () => {
    history.push('/usermanagement');
    this.forceUpdate();
  };

  clickEvents = (events, sourceKey, sourceId, client, level, setEvents) => {
    this.onEventsEnter();
    sessionStorage.setItem('client', client);
    sessionStorage.setItem('typeVehicle', level);

    eventService.postEventsNotification(sourceKey, sourceId);
    const eventsRemove = events.filter(e => e.sourceId !== sourceId);
    setEvents(eventsRemove);

    history.push(
      `/reports?&event=${sourceId}&company=${client}&level=${level}`
    );
    this.forceUpdate();
  };

  clickAboutUs = () => {
    history.push('/about-us');
    this.forceUpdate();
  };

  onCogEnter = ({ root }) => {
    const dropdown = document.getElementById('navDropdownCog');
    if (root === 'enter') {
      dropdown.classList.add('show');
    } else if (root === 'exit') {
      dropdown.classList.remove('show');
    }
  };

  onEventsEnter = () => {
    const dropdown = document.getElementById('navDropdownEvents');
    if (!dropdown.classList.contains('show')) {
      dropdown.classList.add('show');
    } else {
      dropdown.classList.remove('show');
    }
  };

  onEventsEnterSecondary = i => {
    const dropdown = document.getElementById('dp-menu' + i);
    if (dropdown.classList.contains('hidden')) {
      dropdown.classList.remove('hidden');
    } else {
      dropdown.classList.add('hidden');
    }
  };

  render() {
    const {
      showOpctionsHeader,
      showOpctionCustomerHeader,
      setShowOpctionsHeader,
      setShowOpctionCustomerHeader,
      activePath
    } = this.props;
    const filterEvents = this.getEvents();
    const events = Object.values(
      filterEvents.reduce(function(r, a) {
        r[a.trainName] = r[a.trainName] || [];
        r[a.trainName].push(a);
        return r;
      }, Object.create(null))
    );

    return (
      <Navbar bg=" topBarMenu" className="TOPNAV" expand="sm">
        <Navbar.Brand>
          <div className="logoHeader">
            <Logo></Logo>
          </div>
          <span className="brandMenu">Rail CoMo Visualization</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          {showOpctionsHeader ? (
            <Nav className="mr-auto">
              {items.map(item => {
                const active = activePath === item.id;
                if (!showOpctionCustomerHeader && item.label == 'Customers')
                  return <Fragment key={item.id}></Fragment>;
                else
                  return (
                    <NavLink
                      key={item.id}
                      to={item.url}
                      onClick={() => {
                        if (item.label === 'Customers') {
                          setShowOpctionCustomerHeader(false);
                          setShowOpctionsHeader(false);
                          sessionStorage.setItem('client', '');
                          this.props.openCustomers();
                        }
                      }}
                      className={`bp3-button bp3-minimal topmenu__button linkMenu vCenter topmenu-${
                        item.id
                      } ${active ? 'topmenu-active' : ''}`}
                    >
                      <img
                        src={item.icon}
                        className="d-inline-block align-top iconMenu"
                      />
                      <span className="label-topnav">{item.label}</span>
                    </NavLink>
                  );
              })}
            </Nav>
          ) : (
            <Nav className="mr-auto">
              {items.map(item => {
                if (showOpctionCustomerHeader && item.label == 'Customers') {
                  const active = activePath === item.id;
                  return (
                    <NavLink
                      key={item.id}
                      to={item.url}
                      onClick={() => {
                        setShowOpctionsHeader(false);
                        setShowOpctionCustomerHeader(false);
                        sessionStorage.setItem('client', '');
                      }}
                      className={`bp3-button bp3-minimal topmenu__button linkMenu vCenter topmenu-${
                        item.id
                      } ${active ? 'topmenu-active' : ''}`}
                    >
                      <img
                        src={item.icon}
                        className="d-inline-block align-top iconMenu"
                      />
                      <span className="label-topnav">{item.label}</span>
                    </NavLink>
                  );
                }
              })}
            </Nav>
          )}
          <Nav className="mr-auto"></Nav>
          <Nav>
            {false && (
              <li className="nav-item dropdown customDropdown">
                <a
                  onClick={e => {
                    this.onEventsEnter(e);
                  }}
                  className="nav-link dropdown-toggle"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <IconContainer icon={faBell} />
                  {filterEvents && filterEvents.length > 0 && (
                    <Bubble>
                      <BubbleText>
                        {filterEvents && filterEvents.length}
                      </BubbleText>
                    </Bubble>
                  )}
                </a>
                {events && (
                  <div
                    id="navDropdownEvents"
                    className="dropdown-menu scrollNav"
                  >
                    {events &&
                      events.map((e, i) => {
                        return (
                          <div
                            className={'dropdown-item dropdown-item-events'}
                            onClick={() => {
                              this.onEventsEnterSecondary(i);
                            }}
                          >
                            <FontAwesomeIcon
                              className={
                                e[0].assetStatusLabel == 'Plan'
                                  ? 'warningColor'
                                  : 'dangerColor'
                              }
                              icon={
                                e[0].assetStatusLabel == 'Plan'
                                  ? faExclamationTriangle
                                  : faExclamationCircle
                              }
                            />
                            {`           ${e[0].companyName} >  ${e[0].trainName} `}

                            <div
                              id={'dp-menu' + i}
                              className="sub-dropdown-menu hidden"
                            >
                              {e.map((e2, i2) => {
                                return (
                                  <div className="sub-item">
                                    <a
                                      key={i2}
                                      onClick={() =>
                                        this.clickEvents(
                                          this.state.events,
                                          e2.sourceKey,
                                          e2.sourceId,
                                          e2.companyName,
                                          e2.level,
                                          this.setEvents
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        className={
                                          e2.assetStatusLabel == 'Plan'
                                            ? 'warningColor'
                                            : 'dangerColor'
                                        }
                                        icon={
                                          e2.assetStatusLabel == 'Plan'
                                            ? faExclamationTriangle
                                            : faExclamationCircle
                                        }
                                      />
                                      {` ${e2.functionalLocationID}`}
                                    </a>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </li>
            )}

            <li className="nav-item dropdown customDropdown">
              <PageTutorial />
            </li>
            <li
              className="nav-item dropdown customDropdown"
              onMouseOver={() => this.onCogEnter({ root: 'enter' })}
              onMouseLeave={() => this.onCogEnter({ root: 'exit' })}
            >
              <a
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <IconContainer icon={faCog} />
              </a>
              <div id="navDropdownCog" className="dropdown-menu ">
                <a
                  className={`dropdown-item ${
                    window.location.pathname === '/usermanagement'
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => {
                    let hasMultipleCompanies =
                      sessionStorage.getItem('clients') &&
                      sessionStorage.getItem('clients').split(',').length > 1;
                    setShowOpctionsHeader(!hasMultipleCompanies);
                    setShowOpctionCustomerHeader(hasMultipleCompanies);
                    this.clickUserManagement();
                  }}
                >
                  User account
                </a>
                {this.isUserBTAdmin() && (
                  <a
                    className={`dropdown-item ${
                      window.location.pathname === '/about-us' ? 'active' : ''
                    }`}
                    onClick={() => {
                      this.clickAdminPortal();
                    }}
                  >
                    Admin portal
                  </a>
                )}
                <a
                  className="dropdown-item"
                  href="https://skftechnicalsupport.zendesk.com/hc/en-us/requests/new"
                  target="_blank"
                >
                  Get support
                </a>
                <div className="dropdown-divider"></div>
                <a
                  className={`dropdown-item ${
                    window.location.pathname === '/about-us' ? 'active' : ''
                  }`}
                  onClick={() => {
                    let hasMultipleCompanies =
                      sessionStorage.getItem('clients') &&
                      sessionStorage.getItem('clients').split(',').length > 1;
                    setShowOpctionsHeader(!hasMultipleCompanies);
                    setShowOpctionCustomerHeader(hasMultipleCompanies);
                    this.clickAboutUs();
                  }}
                >
                  About
                </a>

                <a
                  className="dropdown-item blueColor"
                  onClick={() => this.clickLogout()}
                >
                  Log out
                </a>
              </div>
            </li>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openSidebar: menu => dispatch({ type: 'OPEN_SIDEBAR', menu }),
    forceUpdateFleet: () => dispatch({ type: 'FORCE_UPDATE_FLEET' }),
    openCustomers: menu => dispatch({ type: 'CHANGE_MENU', menu })
  };
};

function mapStateToProps(state) {
  return {
    state
  };
}

const connectedTopNav = connect(mapStateToProps, mapDispatchToProps)(TopNav);
export { connectedTopNav as TopNav };
