import React, { Component } from 'react';
import SkfModal from './SkfModal';
import moment from 'moment';
import styled, { StyledComponent } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationTriangle,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import { getEventLogs } from '../../services/reports.service';
import { AssetStatusEnum } from '../../constants/assetStatusEnum';
import { LoadingContainer } from './loadingContainer';
import { getAssetIcon } from '../../selectors/getAssetSelector';

interface IEventLog {
  userName: string;
  eventCaseId: string;
  comment: string;
  dateTime: Date;
  status: string;
  color: string;
}

export interface IEventLogProps {
  eventReference: string;
  isOpen: boolean;
  closeModal: Function;
  assetStatus: number;
  createdOn: string;
  createdBy: string;
  sourceKey: string;
}

export interface StateProps {
  events: IEventLog[];
  isLoading: boolean;
}

class EventLog extends Component<IEventLogProps, StateProps> {
  constructor(props: any) {
    super(props);
  }

  public readonly state: Readonly<StateProps> = {
    events: [],
    isLoading: true
  };

  render() {
    var events = this.state.events.sort((a, b) => {
      return new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime();
    });

    //console.debug("events", events);

    return (
      <SkfModal
        size="small"
        isOpen={this.props.isOpen}
        handleClose={this.props.closeModal.bind(this)}
      >
        <SkfModal.Header handleClose={this.props.closeModal.bind(this)}>
          Event Log
        </SkfModal.Header>
        <SkfModal.Content>
          <span style={{ fontSize: 'large' }}>
            Event Case <b>{this.props.eventReference}</b>
          </span>
          {this.state.isLoading === false ? (
            <List>
              <li key={this.props.assetStatus}>
                {getAssetIcon(this.props.assetStatus, '2x')}
                <div>
                  <span className="event-comment">Event Report Created</span>
                  <p className="event-info">
                    {this.props.createdBy}
                    <br />
                    {this.formatDate(new Date(this.props.createdOn))}
                  </p>
                </div>
              </li>
              {events.map(ev => (
                <li key={ev.eventCaseId}>
                  <span className="bullet">{this.getIcon(ev.color)}</span>
                  <div>
                    <span className="event-comment">{ev.status}</span>
                    <p className="event-info">
                      {ev.userName}
                      <br />
                      {this.formatDate(ev.dateTime)}
                    </p>
                  </div>
                </li>
              ))}
            </List>
          ) : (
            <div>
              <LoadingContainer>
                <div className="spinner-border sfk_spinner" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </LoadingContainer>
            </div>
          )}
        </SkfModal.Content>
      </SkfModal>
    );
  }

  componentDidMount() {
    let { sourceKey } = this.props;
    getEventLogs(sourceKey).then(z => {
      //console.log(z);
      z.forEach(
        (item: IEventLog) =>
          (item.dateTime = item.dateTime && new Date(item.dateTime.toString()))
      );
      this.setState({
        events: z,
        isLoading: false
      });
    });
  }

  formatDate = (date: Date | null): string => {
    if (date != null)
      return `${date.getDate()} ${moment.monthsShort(
        date.getMonth()
      )}, ${date.getUTCFullYear()}`;
    else return '';
  };

  getIcon = (color: string): JSX.Element => {
    /*   switch (eventType.toLowerCase()) {
        case 'open':
          return (
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              color="rgb(225, 192, 0)"
              style={{ background: 'white' }}
              size="2x"
            />
          );
        case 'done':
          return (
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="rgb(112, 173, 71)"
              style={{ background: 'white' }}
              size="2x"
            />
          );
        case 'rejected':
          return (
            <FontAwesomeIcon
              icon={faTimesCircle}
              color="rgb(202, 0, 0)"
              style={{ background: 'white' }}
              size="2x"
            />
          );
        default:*/
    return (
      <FontAwesomeIcon
        icon={faCheckCircle}
        color={color}
        aria-hidden="true"
        style={{ background: 'white' }}
        size="2x"
      />
    );
    //}
  };
}

export default EventLog;

const List: StyledComponent<'ul', any, {}, never> = styled.ul`
  list-style-type: none;
  display: block;
  width: 250px;
  max-width: 80%;
  margin: 2rem auto;
  max-height: 500px;
  & > li {
    display: grid;
    grid-template-columns: 10px auto;
    grid-template-rows: auto auto;
    position: relative;
    margin: 0;
    padding-bottom: 1em;
    padding-left: 20px;

    & > .bullet {
      margin-left: -18px;
      z-index: 4;
    }

    & > .trianglebullet {
      margin-left: -21px;
      z-index: 4;
    }

    & > div {
      padding-left: 1rem;
      line-height: 2;

      & > span.event-comment {
        font-weight: bold;
        font-size: larger;
        line-height: 1;
      }

      & > p.event-info {
        line-height: 1.4;
        padding: 0.5rem;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 14px;
      width: 3px;
      background-color: #8d8d8d;
      z-index: -1;
    }

    &:first-child::before {
      top: 15px;
    }

    &:last-child::before {
      height: 6px;
    }
  }
`;
