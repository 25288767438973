import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getTrainByClientDashboardSelector } from '../../selectors/getTrainDashboardSelector';
import { StatusHeader } from '../../models/statusHeader';
import {
  faExclamationCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetConfigurationPanel } from '../../services/userManagment.service';
import { TrainsSummary } from '../../models/train';
import { handleSendStats } from '../../thunks/statsThunks';

const MaxElementsPerPage: number = 7;

const PaginationTableClientsView = ({ clients, page, setPage }: any) => {
  let numpages = Object.keys(clients).length / MaxElementsPerPage;
  if (Object.keys(clients).length % MaxElementsPerPage > 0)
    numpages = numpages + 1;
  var arr: any = [1];
  if (Object.keys(clients).length) {
    arr = [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20
    ];
    arr.length = Math.ceil(numpages - 1);
  }
  let pages = arr.map((e: any, i: number) => {
    return (
      <li
        key={i}
        className={i + 1 === page ? 'page-item active' : 'page-item'}
        onClick={() => setPage(i + 1)}
      >
        {' '}
        <a className="page-link">{i + 1}</a>
      </li>
    );
  });

  return (
    <Fragment>
      <>
        {numpages > 1 && (
          <nav
            aria-label="Page navigation example"
            className="containerPagination"
          >
            <ul className="pagination justify-content-end">
              <li className={page === 1 ? 'page-item disabled' : 'page-item'}>
                <a
                  className="page-link"
                  tabIndex={-1}
                  onClick={() => setPage(page - 1)}
                >
                  Previous
                </a>
              </li>
              {pages}
              <li
                className={
                  Math.round(numpages) === page
                    ? 'page-item disabled'
                    : 'page-item'
                }
              >
                <a
                  className="page-link"
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  Next
                </a>
              </li>
            </ul>
          </nav>
        )}
        <br></br>
      </>
    </Fragment>
  );
};

const TableClientsView = ({
  clients,
  typeClients,
  loading,
  setLoading,
  setClient,
  setVehicleType,
  page
}: any) => {
  const dispatch = useDispatch();
  const statsSent = useSelector((state: any) => state.stats?.statsSent || {});

  const sendStats = (title: string) => {
    if (!statsSent[title]) {
      dispatch(handleSendStats(title));
    }
  };
  return clients.map((client: any, index: number) => {
    if (
      index < page * MaxElementsPerPage &&
      index >= (page - 1) * MaxElementsPerPage
    )
      return (
        <Fragment key={index}>
          <Row className="resetMargins flex1 main-background">
            <Col className="page-box-tab addMarginBottom lineView">
              <Row>
                <Col xs={7} md={7} lg={7} style={{ minWidth: 500 }}>
                  <Row>
                    <Col>
                      <span className="lineViewText black">{client.key}</span>
                    </Col>
                    <Col className="separatorLineView">
                      <span className="lineViewNum black">
                        {client.skf_Trains}
                      </span>
                      <span className="lineViewSubText black">
                        {typeClients[client.key]}
                      </span>
                    </Col>
                    <Col className="separatorLineView">
                      <span className="lineViewNum black">
                        {client.skf_totalRed}
                      </span>
                      <span className="lineViewSubText black">Act</span>
                      <FontAwesomeIcon
                        className={
                          client.skf_totalAmber >= '100'
                            ? 'dangerColor posIconAlert3digitsViewClients'
                            : client.skf_totalAmber >= '10'
                            ? 'dangerColor posIconAlert2digitsViewClients'
                            : 'dangerColor posIconAlertViewClients'
                        }
                        icon={faExclamationCircle}
                      />
                    </Col>
                    <Col>
                      <span className="lineViewNum black">
                        {client.skf_totalAmber}
                      </span>
                      <span className="lineViewSubText black">Plan</span>
                      <FontAwesomeIcon
                        className={
                          client.skf_totalAmber >= '100'
                            ? 'warningColor posIconAlert3digitsViewClients'
                            : client.skf_totalAmber >= '10'
                            ? 'warningColor posIconAlert2digitsViewClients'
                            : 'warningColor posIconAlertViewClients'
                        }
                        icon={faExclamationTriangle}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={1} md={1} lg={3}></Col>
                <Col xs={2} md={2} lg={2}>
                  <Row>
                    <Col className="lineViewText">
                      <Button
                        variant="primary"
                        disabled={loading !== '' ? true : false}
                        onClick={() => {
                          sessionStorage.setItem('filterVehicles', '');
                          sessionStorage.setItem(
                            'typeVehicle',
                            typeClients[client.key]
                          );
                          setClient(client.key);
                          setLoading(client.key);
                          sendStats('Client details viewed for ' + client.key);
                        }}
                      >
                        {loading === client.key
                          ? 'loading...'
                          : ' Show details'}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Fragment>
      );
  });
};

function orderby(
  clients: any,
  filter: string,
  setHasMultipleCompanys: any,
  setShowOpctionCustomerHeader: any,
  setClient: any
) {
  let arrayClients: any = [];
  Object.keys(clients).map(key => {
    clients[key].key = key;
    arrayClients.push(clients[key]);
  });
  if (filter && filter !== '')
    arrayClients = arrayClients
      .sort((a: any, b: any) => parseFloat(a[filter]) - parseFloat(b[filter]))
      .reverse();
  if (arrayClients.length === 1) {
    setHasMultipleCompanys(false);
    setShowOpctionCustomerHeader(false);
    setClient(arrayClients[0].key);
  }

  return arrayClients;
}

const DashboardClientView = ({
  selectedTrains,
  userName,
  totalFilter,
  setClient,
  setShowOpctionCustomerHeader,
  setHasMultipleCompanys
}: any) => {
  const aux: any = {};
  const [filter, setFilter] = useState('skf_Trains');
  const [typeClients, setTypeClients] = useState(undefined);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState('');

  const clients: TrainsSummary[] = useSelector(
    getTrainByClientDashboardSelector
  );

  if (clients && Object.keys(clients).length > 0) {
    sessionStorage.setItem('clients', Object.keys(clients).toString());
  }

  if (clients && Object.keys(clients) && !typeClients)
    GetConfigurationPanel(Object.keys(clients)).then(rest => {
      if (rest && rest.data) {
        rest.data.map((conpany: any) => {
          aux[conpany.CompanyName] = conpany.Level;
          if (rest.data.length === 1)
            sessionStorage.setItem('typeVehicle', conpany.Level);
        });
        setTypeClients(aux);
      }
    });
  return (
    <Fragment>
      <StatusHeader
        totalFilteredTrains={
          selectedTrains.length ? selectedTrains.length : clients.length
        }
        totalRed={0}
        showNumbers={false}
        title={'Customer  Status'}
        totalAmber={0}
        setFilter={setFilter}
        typeVehicle={sessionStorage.getItem('typeVehicle') ?? ''}
        userName={userName}
        showOrderBy={true}
        totalFilter={totalFilter}
      ></StatusHeader>
      <Container
        fluid={true}
        className="DASHBOARD main-background  customHeightDashboard"
      >
        <br></br>
        {clients && typeClients ? (
          <Fragment>
            <TableClientsView
              page={page}
              loading={loading}
              setLoading={setLoading}
              typeClients={typeClients}
              setClient={setClient}
              clients={orderby(
                clients,
                filter,
                setHasMultipleCompanys,
                setShowOpctionCustomerHeader,
                setClient
              )}
            ></TableClientsView>
            <PaginationTableClientsView
              clients={clients}
              page={page}
              setPage={setPage}
            ></PaginationTableClientsView>
          </Fragment>
        ) : (
          <div className="text-center">
            <div
              className="spinner-border sfk_spinner "
              role="status"
              style={{ width: '3rem', height: '3rem' }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </Container>
    </Fragment>
  );
};

DashboardClientView.defaultProps = {
  clients: [],

  userName: ''
};

export { DashboardClientView };
