import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThermometerHalf, faSearch } from '@fortawesome/free-solid-svg-icons';
import Header from '../utils/header/header';
import mergeArrays from '../utils/arraysHelper/mergeArrays';
import FiltersContainer from '../utils/filters/filtersContainer';
import SideHierarchy from './sideBar/sideHierarchy';
import TemperatureChart from '../utils/charts/temperatureChart';
import TemperatureChartfull from '../utils/charts/temperatureChartfull';
import VibrationChart from '../utils/charts/vibrationChart';
import SpeedChart from '../utils/charts/speedChart';
import VibrationChartfull from '../utils/charts/vibrationChartfull';
import SpeedChartfull from '../utils/charts/speedChartfull';
import {
  getAxleboxByFunctionalLocation,
  getSKFcarriages,
  getSKFAxleboxes
} from '../../services/history.service';
import MainFooter from '../footer/mainFooter';
import analyzeIcon from '../../assets/img/generalSvg/analyzeIcon';
import {
  getAllEventsByCustomer,
  GetHierarchyHistoryDetails
} from '../../services/common.service';
import iVibration from '../../assets/img/ic_vibration.png';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons';

const ComponentContainer = styled.div`
  .list-group-item.active {
    border-color: #0f58d6;
    background-color: #0f58d6;
  }
`;
const NoChartIconContainer = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;
const NoChartContainer = styled.div`
  background-color: #fefefe;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
  min-height: 250px;
`;

const BodyContainer = styled.div`
  min-height: calc(100vh - 88px - 116px);
  margin: 0 20px;
  display: flex;
  flex-direction: column;
`;

const DataContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const LeftContainer = styled.div`
  align-self: stretch;
  width: 280px;

  background-color: #fefefe;
  padding: 10px 15px;
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
`;

const RightContainer = styled.div`
  width: 100%;
  margin: 0 20px;
  /*display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center; */
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 10px 30%;
  padding: 60px;
  font-size: 32px;
  text-align: center;
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 0px 20px;
  padding: 60px 0;
  font-size: 32px;
  text-align: center;
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
`;
const NoRenderChart = styled.div`
  min-height: 190px;
  /* display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: white; */
`;
const NoRenderTextCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-grow: 1;
`;

const GeneralLoadingContainer = styled(LoadingContainer)`
  margin-top: 20px;
`;

const IconContainer = styled(FontAwesomeIcon)`
  color: lightgray;
  font-size: 200px;
  margin-bottom: 10px;
`;

const TitleContainer = styled.span`
  font-weight: 700;
  flex: 1;
  padding-left: 5px;
  margin-bottom: 10px;
`;

class HistoryMain extends Component {
  constructor(props) {
    super(props);
    let endDate = this.props.vehicleToHistoryDate;
    if (this.isToday(endDate)) endDate.setDate(endDate.getDate() - 1);

    const startDate = new Date(moment(endDate).subtract(7, 'days'));

    this.state = {
      hierarchySelected: [],
      measuramentTypeSelected: [],
      assetStatusSelected: [],
      startDate: startDate,
      endDate: endDate,
      dataChart: {},
      axleboxData: [],
      sideBarSelected: {},
      hierarchyaStatusData: [],
      hierarchymTypeData: [],
      hierarchyFleetData: [],
      hierarchyAxleData: [],
      loadingGeneralData: false,
      skf_events: [],
      skf_axleboxes: [],
      skf_carriages: [],
      skf_TreeData: [],

      update: 1,
      generalLoader: props.additionalHash,
      speedTimestamp: '',
      temperatureTimestamp: '',
      vibrationTimestamp: '',
      showSpeed: false,
      showVibration: false,
      fullSizevibration: false,
      fullSizespeed: false,
      fullSizetemperature: false
    };
  }

  isToday = someDate => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  async componentDidMount() {
    this.mounted = true;
    this.props.changeMenu('history');
    const client = sessionStorage.getItem('client');
    const level = sessionStorage.getItem('typeVehicle');
    let reference = undefined;
    const [treeData, skf_carriages, skf_axleboxes, events] = await Promise.all([
      GetHierarchyHistoryDetails(client, level),
      getSKFcarriages(client),
      getSKFAxleboxes(client),
      getAllEventsByCustomer()
    ]);
    let additionalAxleboxes = this.state.hierarchyFleetData;
    let selectedAxleboxes = {};

    if (this.props.additionalHash) {
      let assetSelectedFromOutside = skf_axleboxes.find(functionaLocation => {
        return functionaLocation.reference === this.props.additionalHash;
      });

      if (!assetSelectedFromOutside) {
        assetSelectedFromOutside = skf_carriages.find(carriage => {
          return carriage.reference === this.props.additionalHash;
        });
      }

      if (assetSelectedFromOutside.hasOwnProperty('axleboxes')) {
        assetSelectedFromOutside.functionaLocation.forEach(
          functionaLocation => {
            additionalAxleboxes = [
              ...additionalAxleboxes,
              functionaLocation.designation
            ];
            selectedAxleboxes = {
              ...selectedAxleboxes,
              [functionaLocation.reference]: true
            };
          }
        );
      } else if (assetSelectedFromOutside) {
        additionalAxleboxes = [
          ...additionalAxleboxes,
          assetSelectedFromOutside.referenceKey
        ];

        selectedAxleboxes = {
          ...selectedAxleboxes,
          [assetSelectedFromOutside.reference]: true
        };

        reference = assetSelectedFromOutside.referenceKey;
      }
    }

    if (this.mounted) {
      this.setState(
        {
          skf_events: events,
          skf_axleboxes,
          skf_carriages,
          skf_TreeData: treeData,
          hierarchyFleetData: additionalAxleboxes,
          sideBarSelected: selectedAxleboxes
        },
        () => {
          if (additionalAxleboxes.length > 0) {
            const startDate = moment(this.state.startDate).format('YYYY-MM-DD');
            const endDate = moment(this.state.endDate).format('YYYY-MM-DD');

            additionalAxleboxes.forEach(async additionalAxlebox => {
              this.setState({ loadingGeneralData: true }, () => {
                if (this.props.additionalHash) {
                  this.handleGetChartDataByFunctionalLocation({
                    axleboxDesignation: reference,
                    startDate,
                    endDate
                  });
                }
              });
            });
          }
        }
      );
    }
  }
  setFullSize = type => {
    switch (type) {
      case 'vibration':
        this.setState({ fullSizevibration: !this.state.fullSizevibration });
        break;
      case 'speed':
        this.setState({ fullSizespeed: !this.state.fullSizespeed });
        break;
      case 'temperature':
        this.setState({ fullSizetemperature: !this.state.fullSizetemperature });
        break;
      default:
        break;
    }

    this.setState({ fullSize: !this.state.fullSize });
  };

  componentWillUnmount() {
    this.mounted = false;
    this.props.toHistory('', new Date());
    this.props.cleanFromVehicleToHistory();
  }

  getRemainingAxleboxes = () =>
    this.state.skf_axleboxes.filter(functionaLocation => {
      let isFiltered = false;
      this.state.hierarchyFleetData.forEach(data => {
        if (
          functionaLocation.referenceKey === data ||
          functionaLocation.carriageDesignation === data
        ) {
          isFiltered = true;
        }
      });

      return isFiltered;
    });

  setZoomIn = (zoomMin, zoomMax) => {
    this.setState({
      zoomMin: moment(zoomMin).toDate(),
      zoomMax: moment(zoomMax).toDate()
    });
  };

  setZoomOut = () => {
    this.setState({ zoomMin: undefined, zoomMax: undefined });
  };

  historyMainCall = ({ value, root, callAxleboxService, ...rest }) => {
    this.setState({ loadingGeneralData: true });
    if (typeof value === 'string') {
      this.setState(
        prevState => ({ [root]: prevState[root].filter(val => value !== val) }),
        () => {
          let stateToUpdate = this.state;
          if (!callAxleboxService || (!rest && !rest[1].checked)) {
            const axleboxDeleted = this.state.skf_axleboxes.find(
              axle => axle.referenceKey === value
            );
            const carriageDeleted = this.state.skf_carriages.find(
              carriage => carriage.referenceKey === value
            );

            if (axleboxDeleted) {
              stateToUpdate = this.filterAxlebox(
                (rest[1] && rest[1].triggerValue) || value,
                axleboxDeleted,
                stateToUpdate
              );
            } else {
              stateToUpdate = this.filterCarriage(
                (rest[1] && rest[1].triggerValue) || value,
                carriageDeleted,
                stateToUpdate
              );
            }
            this.setState(
              {
                ...stateToUpdate,
                dataChart: {},
                loadingGeneralData: false
              } //console.log('state', this.state)
            );
          }
        }
      );
    } else {
      let stateToUpdate = this.state;

      if (this.state.hierarchyFleetData.includes(value.value)) {
        this.setState(
          {
            [root]: this.state.hierarchyFleetData.filter(
              e => e !== value.value
            ),
            hierarchyFleetData: this.state.hierarchyFleetData.filter(
              e => e !== value.value
            )
          },
          async () => {
            /** Aqui se aÃ±aden eliminan datos de la jerarquia */
            const axleboxDeleted = this.state.skf_axleboxes.find(
              functionaLocation => {
                return functionaLocation.referenceKey === value.value;
              }
            );

            const carriageDeleted = this.state.skf_carriages.find(carriage => {
              return carriage.designation === value.value;
            });

            const treeDataDeleted = this.state.skf_TreeData.find(treeValue => {
              return treeValue.key === value.value;
            });

            if (axleboxDeleted) {
              stateToUpdate = this.filterAxlebox(
                value.value || value,
                axleboxDeleted,
                stateToUpdate
              );
            } else if (carriageDeleted) {
              stateToUpdate = this.filterCarriage(
                value.value || value,
                carriageDeleted,
                stateToUpdate
              );
            }

            this.setState({
              ...stateToUpdate,
              dataChart: {},
              loadingGeneralData: false
            });
          }
        );
      } else {
        this.setState(
          {
            [root]: [...this.state.hierarchyFleetData, value.value],
            hierarchyFleetData: [...this.state.hierarchyFleetData, value.value]
          },
          async () => {
            /** Aqui se aÃ±aden datos de la jerarquia */
            const hierarchyAxleData = this.getRemainingAxleboxes();

            this.setState({ hierarchyAxleData }, async () => {
              if (!this.state.hierarchyAxleData.length) {
                this.setState({ axleboxData: [] });
                return;
              }
              const startDate = moment(this.state.startDate).format(
                'YYYY-MM-DD'
              );
              const endDate = moment(this.state.endDate).format('YYYY-MM-DD');

              this.state.hierarchyAxleData.forEach(async functionaLocation => {
                if (functionaLocation.referenceKey === value.value)
                  this.handleGetChartDataByFunctionalLocation({
                    startDate,
                    endDate,
                    axleboxDesignation: functionaLocation.referenceKey
                  });
              });
            });
          }
        );
      }
    }
  };

  /** This function uses and axlebox Designation, the vehicles and two dates to pint the charts */
  handleGetChartDataByFunctionalLocation = async ({
    startDate,
    endDate,
    axleboxDesignation
  }) => {
    const additionalAxleboxSelected = this.state.skf_axleboxes.find(
      functionaLocation => {
        return functionaLocation.referenceKey === axleboxDesignation;
      }
    );

    if (
      this.state.hierarchyAxleData.includes(
        functionaLocation =>
          functionaLocation.referenceKey ===
          additionalAxleboxSelected.referenceKey
      )
    ) {
      return null;
    }

    const { data: axleboxData } = await getAxleboxByFunctionalLocation({
      axleboxReference: additionalAxleboxSelected.referenceKey,
      vehicleReference: additionalAxleboxSelected.vehicleReference,
      startDate,
      endDate
    });
    const hierarchyDownloaded = axleboxData.map(obj => {
      if (
        obj.functionaLocation.temperatureAlarmLowerLimit !== 0 &&
        obj.functionaLocation.temperatureAlarmUpperLimit !== 0
      )
        return {
          threshold: [
            obj.functionaLocation.temperatureAlarmLowerLimit,
            obj.functionaLocation.temperatureAlarmUpperLimit
          ],
          ...obj
        };
    });
    const thresholdsForCharts = hierarchyDownloaded.map(obj => {
      if (obj) return [...obj.threshold];
      return [];
    });
    let removeDuplicates = Array.from(
      new Set(thresholdsForCharts.map(JSON.stringify)),
      JSON.parse
    );
    if (removeDuplicates.length > 1) removeDuplicates = [];

    this.setState(prevState => {
      const removeDuplicatesHierarchyAxleData = Array.from(
        new Set(
          [...prevState.hierarchyAxleData, additionalAxleboxSelected].map(
            JSON.stringify
          )
        ),
        JSON.parse
      );
      const axleboxDatafilter = axleboxData.filter(e => {
        let find = true;
        prevState.axleboxData.forEach(e2 => {
          if (
            e2.functionaLocation?.reference === e.functionaLocation?.reference
          )
            find = false;
        });
        return find;
      });

      return {
        axleboxData: [...prevState.axleboxData, ...axleboxDatafilter],
        hierarchyAxleData: removeDuplicatesHierarchyAxleData,
        thresholdsForCharts: removeDuplicates,
        loadingGeneralData: false,
        generalLoader: false
      };
    });

    return true;
  };

  handleMeasuramentTypeChange = measuramentTypeSelected => {
    this.setState({
      measuramentTypeSelected: measuramentTypeSelected.map(
        element => element.value
      )
    });
  };

  handleAssetStatusChange = e => {
    let assetStatusSelected = this.state.assetStatusSelected;
    if (assetStatusSelected.includes(e.value)) {
      assetStatusSelected = assetStatusSelected.filter(
        element => element !== e.value
      );
    } else {
      assetStatusSelected.push(e.value);
    }
    this.setState({
      assetStatusSelected: assetStatusSelected
    });
  };

  handleAssetStatusDelete = value => {
    this.setState(prevState => ({
      assetStatusSelected: prevState.assetStatusSelected.filter(
        val => value !== val
      ),
      dataChart: {}
    }));
  };

  handleStartDateChange = date => {
    this.setState(
      {
        startDate: date
      },
      this.handleDatepickerCall()
    );
  };

  handleEndDateChange = date => {
    this.setState(
      {
        endDate: date
      },
      this.handleDatepickerCall()
    );
  };

  handleCleandDataSheet = () => {
    this.setState({
      dataChart: {}
    });
  };

  handleDatepickerCall = () => {
    if (this.state.hierarchyFleetData.length > 0) {
      this.callServiceOnDateChange();
    }
  };

  updateCharts = () => {
    this.setState({ update: this.state.update + 1 });
  };

  callServiceOnDateChange = async () => {
    const hierarchyAxleData = this.getRemainingAxleboxes();
    this.setState({ hierarchyAxleData }, async () => {
      if (!this.state.hierarchyAxleData.length) {
        this.setState({ axleboxData: [] });
        return;
      }
      const startDate = moment(this.state.startDate).format('YYYY-MM-DD');
      const endDate = moment(this.state.endDate).format('YYYY-MM-DD');
      let hierarchyDownloaded = [];
      this.state.hierarchyAxleData.forEach(async axlebox => {
        const getAxleboxesPromise = new Promise(async resolve => {
          const { referenceKey, vehicleReference } = axlebox;

          const { data: axleboxData } = await getAxleboxByFunctionalLocation({
            axleboxReference: referenceKey,
            vehicleReference,
            startDate,
            endDate
          });
          resolve(axleboxData);
        });
        Promise.all([
          getAxleboxesPromise.then(value => {
            hierarchyDownloaded = [...hierarchyDownloaded, ...value];
            this.setState({ axleboxData: hierarchyDownloaded });
          })
        ]);
      });
    });
  };

  handleDataTable = ({ value, root, label, timeStamp }) => {
    /** Busca el timestamp de vibration o speed */

    const result = {
      [label]: {
        ...this.state.dataChart[label],
        [root]: value,
        timeStamp: moment(timeStamp),
        label,
        dateKey: timeStamp
      }
    };

    this.setState(prevState => ({
      dataChart: { ...prevState.dataChart, ...result },
      [`${root}Timestamp`]: timeStamp
    }));
  };

  getTimeStamp = ({ root, timeStamp, label }) => {
    //console.debug('tooltipItem 7 -->>' + timeStamp);
    //switch (root) {
    //    case "temperature":
    //        if (!this.state.showVibration) {
    //            return timeStamp;
    //        }

    //        return this.state.dataChart[label].timeStamp;

    //    case "speed":
    //    case "vibration":
    //        if (this.state.showVibration) {
    //            //console.debug('tooltipItem 8 -->>' + timeStamp);
    return timeStamp;
    //        }
    //        //console.debug('tooltipItem 9 -->>' + timeStamp);
    //        return this.state.dataChart[label].timeStamp;
    //    default:
    //        break;
    //}
  };

  filterCarriage = (bubble, carriageDeleted, stateToUpdate) => {
    let stateToReturn = stateToUpdate;
    if (carriageDeleted)
      carriageDeleted.axleboxes.forEach(axlebox => {
        stateToReturn = this.filterAxlebox(bubble, axlebox, stateToReturn);
      });
    return stateToReturn;
  };

  filterAxlebox = (bubble, axleboxDeleted, stateToUpdate) => {
    let stateToReturn = stateToUpdate;
    const newSideBarSelected = stateToUpdate.sideBarSelected;

    const newDataChart = {};

    const newHierarchySelected = this.state.hierarchyAxleData.filter(
      axleData => {
        return !(
          axleData.designation === bubble ||
          axleData.carriageDesignation === bubble ||
          axleData.referenceKey === bubble
        );
      }
    );

    const newAxleboxData = this.state.axleboxData.filter(axleData => {
      return (
        axleData.functionaLocation.locationName !==
          axleboxDeleted.designation &&
        axleData.functionaLocation.reference !== axleboxDeleted.referenceKey
      );
    });

    if (
      Object.prototype.hasOwnProperty.call(
        newSideBarSelected,
        axleboxDeleted.reference
      )
    ) {
      delete newSideBarSelected[axleboxDeleted.reference];
    }
    const newHierarchyFleetData = this.state.hierarchyFleetData.filter(
      fleetData => {
        return fleetData !== axleboxDeleted.referenceKey;
      }
    );
    stateToReturn = {
      ...stateToReturn,
      hierarchyFleetData: newHierarchyFleetData,
      hierarchyAxleData: newHierarchySelected,
      axleboxData: newAxleboxData,
      dataChart: newDataChart,
      sideBarSelected: newSideBarSelected
    };

    return stateToReturn;
  };

  handleNoData = ({ data, root }) => {
    let result = false;
    data.forEach(value => {
      if (value[root] && Object.keys(value[root]).length > 0) {
        result = true;
      }
    });
    return result;
  };

  showDataChart = () => {
    this.setState({
      showSpeed: true,
      showVibration: true
    });
  };

  hideDatachart = () => {
    this.setState({
      showSpeed: false,
      showVibration: false
    });
  };

  renderDataChart = value => {
    if (
      this.state.temperatureTimestamp === this.state.speedTimestamp &&
      this.state.temperatureTimestamp === this.state.vibrationTimestamp
    ) {
      return value;
    } else if (this.state.showSpeed && this.state.showVibration) {
      return value;
    }
    return '-';
  };

  render() {
    const {
      startDate,
      endDate,
      minDate,
      maxDate,

      axleboxData,
      hierarchyFleetData,
      sideBarSelected,
      hierarchyaStatusData,
      hierarchyAxleData,
      assetStatusSelected,
      hierarchymTypeData,
      thresholdsForCharts,
      skf_TreeData,
      skf_events,
      zoomMax,
      zoomMin
    } = this.state;

    const dataSheet = Object.values(this.state.dataChart);
    let filteredHierarchyAxleData = hierarchyAxleData;
    let filteredAxleboxData = axleboxData;

    const handleSidebarClick = ({ reference }) => {
      this.setState(prevState => ({
        sideBarSelected: {
          ...prevState.sideBarSelected,
          [reference]: !prevState.sideBarSelected[reference]
        }
      }));
    };

    if (
      assetStatusSelected.length > 0 &&
      filteredHierarchyAxleData.length > 0
    ) {
      filteredHierarchyAxleData = filteredHierarchyAxleData.filter(value => {
        let result = false;
        value.event.forEach(event => {
          assetStatusSelected.forEach(status => {
            if (event.assetStatusLabel === status) {
              result = true;
            }
          });
        });
        return result;
      });
    }
    if (assetStatusSelected.length > 0 && filteredAxleboxData.length > 0) {
      filteredAxleboxData = filteredAxleboxData.filter(axleData => {
        let result = false;
        filteredHierarchyAxleData.forEach(hierarchyData => {
          if (
            axleData.functionaLocation.locationName ===
            hierarchyData.designation
          ) {
            result = true;
          }
        });
        return result;
      });
    }
    var date = new Date();
    date.setDate(date.getDate() - 1);
    return (
      <ComponentContainer className="History_Main">
        <Header
          title="History"
          subtitle={sessionStorage.getItem('userName')}
          showNumbers={false}
        />
        <BodyContainer>
          <FiltersContainer
            startDate={startDate || minDate}
            endDate={endDate || maxDate}
            minDate={new Date('1900-01-01')}
            maxDate={new Date()}
            selectedDate={endDate || date}
            mergeArrays={mergeArrays}
            isDownloadReport={true}
            historyMainCall={this.historyMainCall}
            handleEndDateChange={date => this.handleEndDateChange(date)}
            handleAssetStatusChange={this.handleAssetStatusChange}
            handleMeasuramentTypeChange={this.handleMeasuramentTypeChange}
            handleStartDateChange={date => this.handleStartDateChange(date)}
            skf_TreeData={skf_TreeData}
            filteredAxleboxData={filteredAxleboxData}
            hierarchyFleetData={hierarchyFleetData}
            hierarchyaStatusData={hierarchyaStatusData}
            hierarchymTypeData={hierarchymTypeData}
            assetStatusSelected={assetStatusSelected}
            handleAssetStatusDelete={this.handleAssetStatusDelete}
          />
          {this.state.generalLoader ||
          (hierarchyAxleData.length === 0 && hierarchyFleetData.length > 0) ? (
            <GeneralLoadingContainer>
              <div className="spinner-border sfk_spinner" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </GeneralLoadingContainer>
          ) : (
            <DataContainer>
              {filteredHierarchyAxleData &&
              filteredHierarchyAxleData.length > 0 ? (
                <Fragment>
                  <LeftContainer>
                    <TitleContainer>
                      Vehicle
                      <br />
                      &nbsp;
                    </TitleContainer>
                    <SideHierarchy
                      handleSidebarClick={handleSidebarClick}
                      sideBarSelected={this.state.sideBarSelected}
                      hierarchyAxleData={filteredHierarchyAxleData}
                      skf_events={skf_events}
                    />
                  </LeftContainer>
                  {this.state.loadingGeneralData ? (
                    <LoadingContainer>
                      <div className="spinner-border sfk_spinner" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </LoadingContainer>
                  ) : (
                    <RightContainer>
                      {filteredAxleboxData &&
                      this.handleNoData({
                        data: filteredAxleboxData,
                        root: 'temperature'
                      }) ? (
                        this.state.fullSizetemperature ? (
                          <TemperatureChartfull
                            handleCleandDataSheet={() => {
                              return this.handleCleandDataSheet();
                            }}
                            dataHandler={({ label, timeStamp, value }) => {
                              return this.handleDataTable({
                                value,
                                root: 'temperature',
                                label,
                                timeStamp
                              });
                            }}
                            setZoomIn={this.setZoomIn}
                            setZoomOut={this.setZoomOut}
                            setFullSize={this.setFullSize}
                            fullSize={this.state.fullSize}
                            updateCharts={this.updateCharts}
                            axleboxData={filteredAxleboxData}
                            sideBarSelected={sideBarSelected}
                            startDate={zoomMin ? zoomMin : startDate}
                            zoom={zoomMin}
                            endDate={zoomMax ? zoomMax : endDate}
                            threshold={
                              thresholdsForCharts &&
                              thresholdsForCharts.length === 1
                                ? thresholdsForCharts
                                : false
                            }
                          />
                        ) : (
                          <TemperatureChart
                            handleCleandDataSheet={() => {
                              return this.handleCleandDataSheet();
                            }}
                            dataHandler={({ label, timeStamp, value }) => {
                              return this.handleDataTable({
                                value,
                                root: 'temperature',
                                label,
                                timeStamp
                              });
                            }}
                            setZoomIn={this.setZoomIn}
                            setZoomOut={this.setZoomOut}
                            setFullSize={this.setFullSize}
                            fullSize={this.state.fullSize}
                            updateCharts={this.updateCharts}
                            axleboxData={filteredAxleboxData}
                            sideBarSelected={sideBarSelected}
                            startDate={zoomMin ? zoomMin : startDate}
                            zoom={zoomMin}
                            endDate={zoomMax ? zoomMax : endDate}
                            threshold={
                              thresholdsForCharts &&
                              thresholdsForCharts.length === 1
                                ? thresholdsForCharts
                                : false
                            }
                          />
                        )
                      ) : (
                        <NoRenderChart>
                          <NoChartContainer>
                            <div>
                              <NoChartIconContainer icon={faThermometerHalf} />
                              <span>Temperature (ºC)</span>
                            </div>
                            <NoRenderTextCenter>
                              No data found.
                            </NoRenderTextCenter>
                          </NoChartContainer>
                        </NoRenderChart>
                      )}
                      {filteredAxleboxData &&
                      this.handleNoData({
                        data: filteredAxleboxData,
                        root: 'vibration'
                      }) ? (
                        <div
                          onMouseEnter={() => this.showDataChart()}
                          onMouseLeave={() => this.hideDatachart()}
                        >
                          {!this.state.fullSizevibration ? (
                            <VibrationChart
                              dataHandler={({ label, timeStamp, value }) => {
                                return this.handleDataTable({
                                  value,
                                  root: 'vibration',
                                  label,
                                  timeStamp
                                });
                              }}
                              setZoomIn={this.setZoomIn}
                              setZoomOut={this.setZoomOut}
                              setZoom={this.setZoom}
                              updateCharts={this.updateCharts}
                              axleboxData={filteredAxleboxData}
                              setFullSize={this.setFullSize}
                              sideBarSelected={sideBarSelected}
                              startDate={zoomMin ? zoomMin : startDate}
                              zoom={zoomMin}
                              endDate={zoomMax ? zoomMax : endDate}
                              threshold={
                                thresholdsForCharts &&
                                thresholdsForCharts.length === 1
                                  ? thresholdsForCharts
                                  : false
                              }
                            />
                          ) : (
                            <VibrationChartfull
                              dataHandler={({ label, timeStamp, value }) => {
                                return this.handleDataTable({
                                  value,
                                  root: 'vibration',
                                  label,
                                  timeStamp
                                });
                              }}
                              setZoomIn={this.setZoomIn}
                              setZoomOut={this.setZoomOut}
                              updateCharts={this.updateCharts}
                              axleboxData={filteredAxleboxData}
                              setFullSize={this.setFullSize}
                              sideBarSelected={sideBarSelected}
                              startDate={zoomMin ? zoomMin : startDate}
                              zoom={zoomMin}
                              endDate={zoomMax ? zoomMax : endDate}
                              threshold={
                                thresholdsForCharts &&
                                thresholdsForCharts.length === 1
                                  ? thresholdsForCharts
                                  : false
                              }
                            ></VibrationChartfull>
                          )}
                        </div>
                      ) : (
                        <NoRenderChart>
                          <NoChartContainer>
                            <div>
                              <img alt="" src={iVibration} />
                              <span>&nbsp;Vibration (gE)</span>
                            </div>
                            <NoRenderTextCenter>
                              No data found.
                            </NoRenderTextCenter>
                          </NoChartContainer>
                        </NoRenderChart>
                      )}
                      {filteredAxleboxData &&
                      this.handleNoData({
                        data: filteredAxleboxData,
                        root: 'speed'
                      }) ? (
                        <div
                          onMouseEnter={() => this.showDataChart()}
                          onMouseLeave={() => this.hideDatachart()}
                        >
                          {!this.state.fullSizespeed ? (
                            <SpeedChart
                              dataHandler={({ label, timeStamp, value }) => {
                                return this.handleDataTable({
                                  value,
                                  root: 'speed',
                                  label,
                                  timeStamp
                                });
                              }}
                              setZoomIn={this.setZoomIn}
                              setZoomOut={this.setZoomOut}
                              updateCharts={this.updateCharts}
                              axleboxData={filteredAxleboxData}
                              setFullSize={this.setFullSize}
                              sideBarSelected={sideBarSelected}
                              startDate={zoomMin ? zoomMin : startDate}
                              zoom={zoomMin}
                              endDate={zoomMax ? zoomMax : endDate}
                              threshold={
                                thresholdsForCharts &&
                                thresholdsForCharts.length === 1
                                  ? thresholdsForCharts
                                  : false
                              }
                            />
                          ) : (
                            <SpeedChartfull
                              dataHandler={({ label, timeStamp, value }) => {
                                return this.handleDataTable({
                                  value,
                                  root: 'speed',
                                  label,
                                  timeStamp
                                });
                              }}
                              setZoomIn={this.setZoomIn}
                              setZoomOut={this.setZoomOut}
                              updateCharts={this.updateCharts}
                              axleboxData={filteredAxleboxData}
                              setFullSize={this.setFullSize}
                              sideBarSelected={sideBarSelected}
                              startDate={zoomMin ? zoomMin : startDate}
                              zoom={zoomMin}
                              endDate={zoomMax ? zoomMax : endDate}
                              threshold={
                                thresholdsForCharts &&
                                thresholdsForCharts.length === 1
                                  ? thresholdsForCharts
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : (
                        <NoRenderChart>
                          <NoChartContainer>
                            <div>
                              <NoChartIconContainer icon={faTachometerAlt} />
                              <span>Speed (km/h)</span>
                            </div>
                            <NoRenderTextCenter>
                              No data found.
                            </NoRenderTextCenter>
                          </NoChartContainer>
                        </NoRenderChart>
                      )}
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Functional location</th>
                            <th>Temperature</th>
                            <th>Vibration</th>
                            <th>Speed</th>
                            {/*<th>GPS</th>*/}
                            <th>Timestamp</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataSheet &&
                            dataSheet.map(data => {
                              const axleboxReferences = [];
                              const currentAxleData = this.state.axleboxData.find(
                                axleData => {
                                  if (
                                    data.label ===
                                    axleData.functionaLocation.locationName
                                  )
                                    return axleData;
                                }
                              );
                              let pintar = false;
                              this.state.axleboxData.forEach(axleData => {
                                axleboxReferences.push(
                                  axleData.functionaLocation
                                );
                              });

                              axleboxReferences.forEach(reference => {
                                if (
                                  data.label === reference.locationName &&
                                  sideBarSelected[reference.reference]
                                )
                                  pintar = true;
                              });
                              //const gpsDates =
                              //    (currentAxleData &&
                              //        currentAxleData.locations &&
                              //        currentAxleData.locations.map(
                              //            location => new Date(location.timestamp)
                              //        )) ||
                              //    [];
                              //const target = new Date(
                              //    moment(data.timeStamp, [
                              //        "DD-MM-YY HH:mm"
                              //    ]).format("lll")
                              //);
                              // const index = nearest(gpsDates, target);
                              if (currentAxleData !== undefined) {
                                return (
                                  <tr
                                    key={`key${data.label}`}
                                    className={`${
                                      pintar ? 'bg-primary text-white' : ''
                                    }`}
                                  >
                                    <td>{data.label}</td>
                                    <td>
                                      {(currentAxleData.temperature &&
                                        currentAxleData.temperature[
                                          data.dateKey
                                        ] &&
                                        currentAxleData.temperature[
                                          data.dateKey
                                        ] &&
                                        currentAxleData.temperature[
                                          data.dateKey
                                        ].toLocaleString(navigator.language, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })) ||
                                        (currentAxleData.temperature &&
                                          currentAxleData.temperature[
                                            data.dateKey
                                          ] &&
                                          currentAxleData.temperature[
                                            data.dateKey
                                          ].toLocaleString(navigator.language, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })) ||
                                        '-'}{' '}
                                      ºC
                                    </td>
                                    <td>
                                      {(currentAxleData.vibration &&
                                        currentAxleData.vibration[
                                          data.dateKey
                                        ] &&
                                        currentAxleData.vibration[
                                          data.dateKey
                                        ].toLocaleString(navigator.language, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })) ||
                                        '-'}{' '}
                                      gE
                                    </td>
                                    <td>
                                      {(currentAxleData.speed &&
                                        currentAxleData.speed[data.dateKey] &&
                                        currentAxleData.speed[
                                          data.dateKey
                                        ].toLocaleString(navigator.language, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })) ||
                                        '-'}{' '}
                                      km/h
                                    </td>
                                    {/* <td>
                                                                                    <div>{`lat:${(index >= 0 &&
                                                                                        currentAxleData.locations &&
                                                                                            currentAxleData.locations[index]
                                                                                                .latitude
                                                                                        ) ||
                                                                                        "-"}`}</div>
                                                                                    <div>{`lng:${(index >= 0 &&
                                                                                        currentAxleData.locations &&
                                                                                            currentAxleData.locations[index]
                                                                                                .longitude
                                                                                        ) ||
                                                                                        "-"}`}</div>
                                                                                </td>*/}
                                    <td>
                                      {moment(data.timeStamp, [
                                        'DD-MM-YY HH:mm:ss'
                                      ]).format('MMM DD, YYYY HH:mm:ss')}
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                        </tbody>
                      </table>{' '}
                    </RightContainer>
                  )}
                </Fragment>
              ) : (
                <NoDataContainer>
                  {hierarchyFleetData.length > 0 &&
                  filteredHierarchyAxleData.length <= 0 ? (
                    <Fragment>
                      <IconContainer icon={faSearch} />
                      <div>
                        No data were found. Try another filter selection
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {/* <IconContainer icon={faSitemap} /> */}
                      {/* import aboutSKFLogo from "../../assets/img/generalSvg/aboutSKFLogo"; */}
                      {analyzeIcon}
                      <div>
                        Select a vehicle or asset from the list in the filter
                        for detailed view
                      </div>
                    </Fragment>
                  )}
                </NoDataContainer>
              )}
            </DataContainer>
          )}
        </BodyContainer>

        <MainFooter />
      </ComponentContainer>
    );
  }
}

/** This function is  used to change between the differents menus from the navBar */
const mapDispatchToProps = dispatch => {
  return {
    toHistory: (payload, date) => {
      dispatch({ type: 'SET_DATA_VEHICLES_TO_HISTORY', payload, date });
    },
    cleanFromVehicleToHistory: () => dispatch({ type: 'CLEAN_TO_HISTORY' }),
    changeMenu: menu => dispatch({ type: 'CHANGE_MENU', menu })
  };
};

const mapStateToProps = state => {
  return {
    additionalHash: state.carrier.hashes.vehiclesToHistory,
    vehicleToHistoryDate: state.carrier.date.vehicleToHistoryDate
  };
};

const connectedHistoryPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryMain);

HistoryMain.defaultProps = {
  changeMenu: () => null,
  skf_TreeData: [],
  skf_axleboxes: [],
  skf_carriages: [],
  skf_events: []
};

HistoryMain.propTypes = {
  changeMenu: PropTypes.func,
  skf_TreeData: PropTypes.array,
  skf_axleboxes: PropTypes.array,
  skf_carriages: PropTypes.array,
  skf_events: PropTypes.array
};

// eslint-disable-next-line import/prefer-default-export
export { connectedHistoryPage as HistoryMain };
