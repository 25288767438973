import React, { Component, Fragment } from 'react';
import SkfModal from '../reports/SkfModal';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { fleetService } from '../../services/fleet.service';
import { toast } from 'react-toastify';
import TreeSelectComponent from '../utils/filters/treeSelect';
import BubbleTree from '../utils/filters/bubbleTree';

interface IEventLog {
  userName: string;
  eventCaseId: string;
  comment: string;
  dateTime: Date;
  status: string;
  color: string;
  roles: string[];
}

export interface IEventLogProps {
  eventReference: string;
  isOpen: boolean;
  closeModal: any;
  assetStatus: number;
  createdOn: string;
  createdBy: string;
  categorys: any;
  setCategorys: any;
  setDocuments: any;
  treeData: any;
  documents: any;
  setID: string;
  vehicleNumber: string;
  bogiePosition: string;
}

export interface StateProps {
  events: IEventLog[];
  isLoading: boolean;
  validated: boolean;
  categorys: any;
  title: string;
  descripction: string;
  category: string;
  vehicleReference: string[];
  file: any;
  validFrom: string;
  validUntil: string;
  permanently: boolean;
  allVehicles: boolean;
}

class ModalNewDocument extends Component<IEventLogProps, StateProps> {
  constructor(props: any) {
    super(props);
  }

  public readonly state: Readonly<StateProps> = {
    events: [],
    isLoading: true,
    category: '',
    validated: false,
    vehicleReference: [],
    permanently: false,
    setID: '',
    vehicleNumber: '',
    bogiePosition: ''
  };

  setCategory = (e: string) => {
    this.setState({ category: e });
  };

  restePage = () => {
    this.setState({
      title: '',
      descripction: '',
      category: '',
      vehicleReference: []
    });
  };

  addDocument = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ validated: true });
    }

    event.preventDefault();
    const client = sessionStorage.getItem('client');
    const level = sessionStorage.getItem('typeVehicle');
    const formData = new FormData();
    let validFrom = this.state.validFrom
      ? new Date(this.state.validFrom)
      : new Date();
    let validUntil = this.state.validUntil
      ? new Date(this.state.validUntil)
      : new Date();

    const dataFile: any = {
      title: this.state.title,
      description: this.state.descripction,
      category: this.state.category,
      permanently: this.state.permanently,
      level: level,
      setID: this.props.setID,
      vehicleNumber: this.props.vehicleNumber,
      bogiePosition: this.props.bogiePosition,
      allvehicles: this.state.allvehicles,
      companyName: client,
      validFrom: new Date(validFrom)
        .toISOString()
        .replace('T', ' ')
        .replace('Z', ''),
      validUntil: new Date(validUntil)
        .toISOString()
        .replace('T', ' ')
        .replace('Z', ''),
      levelsRef: this.getKeyTreeData(this.state.vehicleReference)
    };

    if (!client) client = '';
    if (!this.state.file || !this.state.file?.name) return;

    formData.append('File', new Blob([this.state.file]), this.state.file?.name);
    formData.append('Category', this.state.category);
    formData.append('CompanyName', client);
    formData.append('level', level);
    formData.append('setId', this.props.setID);
    formData.append('vehicleNumber', this.props.vehicleNumber);
    formData.append('bogiePosition', this.props.bogiePosition);
    if (dataFile.permanently)
      formData.append('permanently', dataFile.permanently);
    if (dataFile.allvehicles)
      formData.append('allvehicles', dataFile.allvehicles);
    fleetService.insertDocumentFile(formData).then(e => {
      dataFile.uploadFile = e;
      dataFile.fileName = this.state.file?.name;
      fleetService.insertDocumentData(dataFile).then(e => {
        try {
          this.props.setDocuments(e);
          this.setState({ validated: false });
          toast.success('Document added.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 6000
          });
          this.restePage();
          this.props.closeModal();
        } catch (error) {
          console.log(error);
          this.restePage();
          this.props.closeModal();
        }
      });
      event.preventDefault();
    });
  };

  handleHierarchyFleetOnChange = ({ value }) => {
    let aux = this.state.vehicleReference;

    if (aux.includes(value)) {
      aux = aux.filter(e => e !== value);
    } else {
      aux.push(value);
    }
    this.setState({ vehicleReference: aux });
  };

  handleHierarchyFleetOnChangeBubble = ({ value }) => {
    let vehicleReferenceFilter = this.state.vehicleReference.filter(
      e => e !== value
    );
    this.setState({ vehicleReference: vehicleReferenceFilter });
  };

  getKeyParents = (listID: string) => {
    let keyParents: string[] = [];
    let fisrtKey,
      secondKey,
      thridKey = undefined;

    keyParents.push(this.getKeyTitle(listID));
    fisrtKey = this.getKeyParent(listID);

    if (fisrtKey || fisrtKey === 0) {
      keyParents.push(this.getKeyTitle(fisrtKey));
      secondKey = this.getKeyParent(fisrtKey);
    }
    if (secondKey) {
      keyParents.push(this.getKeyTitle(secondKey));
      thridKey = this.getKeyParent(secondKey);
    }
    if (thridKey) {
      keyParents.push(this.getKeyTitle(thridKey));
    }
    return keyParents;
  };

  getKeyParent = (key: string) => {
    const parent = this.props.treeData?.find(e => e.key === key);
    return parent && parent.pId;
  };

  getKeyTitle = (key: string) => {
    const node = this.props.treeData?.find(e => e.key === key);
    return node && node.title;
  };

  getKeyTreeData = (listID: string[]) => {
    let keyTreeData: any = [];
    listID.forEach(element => {
      keyTreeData.push(
        this.getKeyParents(
          this.props.treeData?.find(e => e.value === element).key
        )
      );
    });
    return this.formatKeyTreeData(keyTreeData);
  };

  formatKeyTreeData = (listKeys: any) => {
    let arrayKeys: any = [];
    listKeys.map((element: any, i) => {
      const aux: any = {};
      switch (element.length) {
        case 2:
          aux.setId = element[0];
          aux.traction = element[1];

          break;
        case 3:
          aux.vehicleNumber = element[0];
          aux.setId = element[1];
          aux.traction = element[2];

          break;
        case 4:
          aux.bogiePosition = element[0];
          aux.vehicleNumber = element[1];
          aux.setId = element[2];
          aux.traction = element[3];
          break;

        default:
          break;
      }
      arrayKeys[i] = aux;
    });
    return arrayKeys;
  };

  handleBubbleClick = () => {
    alert('press');
  };

  setPermanently = () => {
    this.setState({ permanently: !this.state.permanently });
  };

  setAllVehicles = () => {
    this.setState({ allVehicles: !this.state.allVehicles });
  };

  render() {
    let titleInUse = false;

    if (this.state.file)
      if (
        this.props.documents?.find(
          e =>
            e.title === this.state.title && e.fileName === this.state.file?.name
        )
      )
        titleInUse = true;

    let treeData = this.props.treeData?.map((e: any) => (e.disabled = false));

    return (
      <SkfModal
        size="large"
        isOpen={this.props.isOpen}
        handleClose={this.props.closeModal}
      >
        <SkfModal.Header handleClose={this.props.closeModal}>
          Online Repository
        </SkfModal.Header>
        <SkfModal.Content>
          <Form
            style={{ width: '90%' }}
            noValidate
            validated={this.state.validated}
            onSubmit={this.addDocument}
          >
            <span style={{ fontSize: 'large' }}>
              Please fill the fields out in order to upload a new document
            </span>
            <br />
            <br />
            <Form.Group
              sm="6"
              as={Row}
              className="mb-3"
              controlId="formPlaintextTitle"
            >
              <Form.Label column>Title</Form.Label>
              <Col sm="12">
                <Form.Control
                  type="text"
                  placeholder="Title"
                  onChange={e => this.setState({ title: e.target.value })}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group
              sm="6"
              as={Row}
              className="mb-3"
              controlId="formPlaintextDescription"
            >
              <Form.Label column>Description</Form.Label>
              <Col sm="12">
                <Form.Control
                  onChange={e =>
                    this.setState({ descripction: e.target.value })
                  }
                  as="textarea"
                  rows={3}
                  placeholder="Description"
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group
              sm="6"
              as={Row}
              className="mb-3"
              controlId="formPlaintextCategory"
            >
              <Form.Label column>Category</Form.Label>
              <Col sm="12">
                <Form.Control
                  required
                  onChange={e => this.setCategory(e.target.value)}
                  as="select"
                >
                  <option value={''}>{''}</option>
                  {this.props.categorys
                    .filter(c => c.status === 'ENABLED')
                    .map((e: any, i: number) => (
                      <option key={i} value={e.category}>
                        {e.category}
                      </option>
                    ))}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group
              sm="6"
              as={Row}
              className="mb-3"
              controlId="formCheckboxPermanently"
            >
              <Col sm="12">
                <Form.Check
                  checked={this.state.permanently}
                  type="checkbox"
                  label="Permanently"
                  onChange={this.setPermanently}
                />
              </Col>
            </Form.Group>
            {!this.state.permanently && (
              <Fragment>
                <Row>
                  <Form.Group
                    lg="6"
                    md="12"
                    as={Col}
                    className="mb-3"
                    controlId="dateFrom"
                  >
                    <Row>
                      <Form.Label column sm="2">
                        Valid from
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          type="date"
                          placeholder="Date"
                          required
                          onChange={e =>
                            this.setState({ validFrom: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    lg="6"
                    md="12"
                    as={Col}
                    className="mb-6"
                    controlId="dateUntil"
                  >
                    <Row>
                      <Form.Label column sm="2">
                        to
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          type="date"
                          placeholder="Date"
                          required
                          onChange={e =>
                            this.setState({ validUntil: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
              </Fragment>
            )}
            <Form.Group sm="6" as={Row} className="mb-6" controlId="file">
              <Form.Label column>File</Form.Label>
              <Col sm="12">
                <Form.Control
                  required
                  type="file"
                  onChange={e => this.setState({ file: e.target.files[0] })}
                />
              </Col>
            </Form.Group>
            <br></br>
            <br></br>
            <Form.Group
              sm="6"
              as={Row}
              className="mb-3"
              controlId="formCheckboxPermanently"
            >
              <Col sm="12">
                <Form.Check
                  checked={this.state.allVehicles}
                  type="checkbox"
                  label="All vehicles"
                  onChange={this.setAllVehicles}
                />
              </Col>
            </Form.Group>
            {!this.state.allVehicles && (
              <Fragment>
                <Form.Group sm="12" as={Row}>
                  <Form.Label column>Refered</Form.Label>
                  <TreeSelectComponent
                    placeholder={'Refered'}
                    title=""
                    skf_TreeData={this.props.treeData}
                    onChange={this.handleHierarchyFleetOnChange}
                    value={this.state.vehicleReference}
                    fullweight
                    singleSelect
                  />
                </Form.Group>
                <Col sm="12">
                  <BubbleTree
                    handleBubbleClick={this.handleHierarchyFleetOnChangeBubble}
                    hierarchyFleetData={this.state.vehicleReference}
                  />
                </Col>
              </Fragment>
            )}
            <Col sm="12">
              {titleInUse && (
                <Form.Label style={{ color: 'red' }} column>
                  Title and document already exist.
                </Form.Label>
              )}
            </Col>
            <br /> <br />
            <Button type="submit" variant="primary">
              Add document
            </Button>{' '}
          </Form>
        </SkfModal.Content>
      </SkfModal>
    );
  }
}

export default ModalNewDocument;
