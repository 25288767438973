import axios from 'axios';
let requestUrl;

// Capture all api request. If error == 401, this method call api for new token (refresh)
export default {
  setupInterceptors: store => {
    axios.interceptors.request.use(request => {
      const url = request.url;
      request.originalRequest = url;
      requestUrl = url;
      request.retry = 3;
      request.retryDelay = 1000;
      return request;
    });
    // axios.interceptors.response.use(
    //   response => {
    //     return response;
    //   },
    //   error => {
    //     return Promise.reject(error);
    //   }
    // );

    axios.interceptors.response.use(undefined, error => {
      console.log('checking the response on the interceptor');
      const {
        config,
        message
      } = error;
      if (!config || !config.retry) {
        return Promise.reject(error);
      }

      // retry while Network timeout or Network Error
      console.log('this is the messages: ' + message);
      if (!(message.includes('timeout') || message.includes('Network Error'))) {
        console.log('*** timeout and network error check ***');
        return Promise.reject(error);
      }
      console.log('*** it has network error on the message ***');
      config.retry -= 1;
      if (config.retry <= 0) {
        console.log('No more retries left');
        return Promise.reject(error);
      }
      const delayRetryRequest = new Promise(resolve => {
        setTimeout(() => {
          console.log('retry the request', config.url);
          resolve();
        }, config.retryDelay || 1000);
      });
      return delayRetryRequest.then(() => axios(config));
    });
  }
};