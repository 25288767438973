import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { history } from '../../../helpers';
import { InfoBar, MainHeader, Wherealarm } from './styledComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faExclamationCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Row, Col, Button } from 'react-bootstrap';
import { setTimeZoneGlobal } from '../../../helpers/timeZone/timeZoneActions';
import { useDispatch } from 'react-redux';
import { format, toDate } from 'date-fns-tz';
import { AlertStatus } from '../../../models/alerts';

const BracketIcon = styled(FontAwesomeIcon)`
  margin: 0 27px;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
`;

const Header = ({
  title,
  subtitle,
  firstText,
  firstNumber,
  secondNumber,
  thirdNumber,
  secondText,
  thirdText,
  showNumbers,
  showOrderBy,
  setFilter,
  totalFilter,
  typeVehicle
}) => {
  let clasesSecondNumber = 'dangerColor posIconAlertRed';
  let clasesThirdNumber = 'warningColor posIconAlert';

  if (secondNumber >= 10)
    clasesSecondNumber = 'dangerColor posIconAlert2digits';
  if (secondNumber >= 100)
    clasesSecondNumber = 'dangerColor posIconAlert3digits';

  if (thirdNumber >= 10) clasesThirdNumber = 'warningColor posIconAlert2digits';
  if (thirdNumber >= 100)
    clasesThirdNumber = 'warningColor posIconAlert3digits';

  const [timeZone, setTimeZone] = useState('');
  const dispatch = useDispatch();
  const customer = sessionStorage.getItem('client');

  const getOffset = timeZone => {
    const now = new Date();
    const zonedDate = toDate(now, { timeZone });
    return format(zonedDate, 'XXX', { timeZone });
  };

  const getLabelToShowTimeZone = timeZone => {
    const offset = getOffset(timeZone);

    let result = '';
    const labelDefaultOffset = 'UTC+00:00';
    if (timeZone === 'UTC') {
      result = labelDefaultOffset;
    } else if (offset === 'Z') {
      result = timeZone + ' ' + labelDefaultOffset;
    } else {
      result = timeZone + ' ' + offset;
    }

    return result;
  };

  useEffect(() => {
    const fetchTimeZone = async () => {
      try {
        let token = sessionStorage.getItem('idToken');

        if (!token) {
          console.error('No token found');
          return;
        }

        if (!token.startsWith('Bearer ')) {
          token = `Bearer ${token}`;
        }

        if (customer !== null && customer.trim() !== '') {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/Users/GetTimeZone?company=${customer}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: token
              }
            }
          );

          if (response.ok) {
            const data = await response.json();
            setTimeZone(data);
            dispatch(setTimeZoneGlobal(data));
          } else if (response.status === 401) {
            console.error('Unauthorized access - 401');
          } else {
            console.error(
              'Error en la respuesta del servidor:',
              response.statusText
            );
          }
        }
      } catch (error) {
        console.error('Error Fetching data:', error);
      }
    };

    fetchTimeZone();
  }, [customer, dispatch]);

  const timeZoneContainer = getLabelToShowTimeZone(timeZone);

  // Manejar el clic del botón "Change"
  const handleChangeClick = () => {
    history.push('/usermanagement');
  };

  return (
    <InfoBar className="d-flex align-items-center">
      <Col xl={10} md={10} xs={10} className="text-center">
        <Row className="pt-4 pb-3 d-flex align-items-center">
          <Col xl={3} md={3} xs={3} className="text-left">
            <MainHeader>{title}</MainHeader>
          </Col>
          {showNumbers &&
            firstNumber >= 0 &&
            secondNumber >= 0 &&
            thirdNumber >= 0 && (
              <Fragment>
                <Col xl={1} md={1} xs={1}>
                  <span className="d-block dashboardHeader text-center battleship">
                    {firstNumber}
                  </span>
                  <span className="d-block customText battleship">
                    {firstText}
                  </span>
                </Col>
                <Col
                  xl={1}
                  md={1}
                  xs={1}
                  className="text-center pl-0 pr-0 position-relative"
                >
                  <BracketIcon icon={faChevronRight} />
                </Col>
                <Col
                  xl={1}
                  md={1}
                  xs={1}
                  className={
                    title === 'Status'
                      ? 'text-center position-relative clickable-block'
                      : 'text-center position-relative'
                  }
                  onClick={() => {
                    if (totalFilter) {
                      totalFilter(AlertStatus.RED);
                    }
                  }}
                >
                  <span className="d-block dashboardHeader dark">
                    {secondNumber}
                  </span>
                  <span className="d-block customText battleship">Act</span>
                  <FontAwesomeIcon
                    className={clasesSecondNumber}
                    icon={faExclamationCircle}
                  />
                </Col>
                <Col
                  xl={1}
                  md={1}
                  xs={1}
                  className="d-flex align-self-start h-100"
                >
                  <Wherealarm className="text-left">{secondText}</Wherealarm>
                </Col>

                <Col
                  xl={1}
                  md={1}
                  xs={1}
                  className={
                    title === 'Status'
                      ? 'text-center position-relative clickable-block'
                      : 'text-center position-relative'
                  }
                  onClick={() => {
                    if (totalFilter) {
                      totalFilter(AlertStatus.AMBER);
                    }
                  }}
                >
                  <span className="d-block dashboardHeader dark">
                    {thirdNumber ? thirdNumber : '0'}
                  </span>
                  <span className="d-block customText battleship">Plan</span>
                  <FontAwesomeIcon
                    className={clasesThirdNumber}
                    icon={faExclamationTriangle}
                  />
                </Col>
                <div className="d-flex align-self-start h-100">
                  <Wherealarm className="text-left">{thirdText}</Wherealarm>
                </div>
              </Fragment>
            )}
          <Col xl={1} md={1} xs={1} className="d-none d-sm-block" />
        </Row>
      </Col>
      <Col xl={2} md={2} xs={2} className="vCenter customerName pt-2">
        <span className="marginRight25 customText18 dark">{customer}</span>
        <br />
        {!/^Customer\s+Status$/.test(title) && (
          <span className="marginRight25">
            Time zone: {timeZoneContainer}
            <Button
              variant="link"
              onClick={handleChangeClick}
              style={{ marginLeft: '10px', padding: '0', fontSize: '14px' }}
            >
              Change
            </Button>
          </span>
        )}
        {showOrderBy && (
          <form className="orderbySelector">
            <div className="form-group">
              <label
                htmlFor="exampleFormControlSelect1"
                className="labelOrderby"
              >
                Order by
              </label>
              <select
                className="form-control"
                id="exampleFormControlSelect1"
                onChange={e => setFilter(e.target.value)}
              >
                <option value="skf_Trains">Level</option>
                <option value="skf_totalRed">Act</option>
                <option value="skf_totalAmber">Plan</option>
              </select>
            </div>
          </form>
        )}
      </Col>
    </InfoBar>
  );
};

Header.defaultProps = {
  firstNumber: 0,
  secondNumber: 0,
  thirdNumber: 0,
  secondText: '',
  thirdText: '',
  showNumbers: true,
  showOrderBy: false,
  typeVehicle: 'Trains'
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  firstNumber: PropTypes.number,
  secondNumber: PropTypes.number,
  thirdNumber: PropTypes.number,
  secondText: PropTypes.string,
  thirdText: PropTypes.string,
  showNumbers: PropTypes.bool,
  typeVehicle: PropTypes.string,
  showOrderBy: PropTypes.bool,
  setFilter: PropTypes.func
};

export default Header;
